import { useContext, useEffect, useState } from "react";
import { Button, Checkbox } from "react-daisyui";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { InputDefault } from "src/components/input/input-default";
import { InputImage } from "src/components/input/input-image";
import { InputSelect } from "src/components/input/input-select";
import { InputTextarea } from "src/components/input/input-textarea";
import { UserContext } from "src/context/UserContext";
import { DigitalProductServices } from "src/services/DigitalProductServices";
import { MenuServices } from "src/services/MenuServices";
import { ProductsServices } from "src/services/ProductsServices";
import { SubmenusServices } from "src/services/SubmenusServices";
import { TenantsServices } from "src/services/TenantsServices";

export function ProductAddPage() {
  const navigate = useNavigate();
  const productsServices = new ProductsServices();
  const submenuServices = new SubmenusServices();
  const menuServices = new MenuServices();
  const tenantsServices = new TenantsServices();
  const digitalProductServices = new DigitalProductServices()

  const { user } = useContext(UserContext);

  const [tenants, setTenants] = useState([]);
  const [menus, setMenus] = useState([]);
  const [submenus, setSubmenus] = useState([]);
  const [digitalProducts, setDigitalProducts] = useState([]);

  const [product, setProduct] = useState({
    name: "",
    description: "",
    stock: "",
    originalPrice: "",
    price: "",
    displayPic: "",
    tenantId: "",
    menuId: "",
    submenuId: "",
    digitalProductId: "-",
    additionalData: "",
  });

  const [subscriptionDay, setSubscriptionDay] = useState(null)

  const [preview, setPreview] = useState(null);

  const [clickAction, setClickAction] = useState(false)

  useEffect(() => {
    if (user.userRole == "manager"){
      fetchTenants()
    } else {
      fetchMenu()
    }

    fetchDigitalProduct();
  }, []);

  async function fetchTenants() {
    const res = await tenantsServices.gets({ offset: 0, limit: 100 });

    if (res?.message != null) {
      toast.error(res.message[0]);
    } else if (res) {
      const temp = res.data.map((item) => {
        return {
          label: item.name,
          value: item.id,
        };
      });

      setProduct((prevState) => ({
        ...prevState,
        tenantId: temp[0].value,
      }));
      setTenants(temp);
      await fetchTenantMenus({ tenantId: temp[0].value });
    }
  }

  async function fetchTenantMenus({tenantId}) {
    const res = await tenantsServices.getMenus({ tenantId });

    if (res?.message != null) {
      toast.error(res.message[0]);
    } else if (res) {
      const temp = res.data.map((item) => {
        return {
          label: item.name,
          value: item.id,
        };
      });

      setProduct((prevState) => ({
        ...prevState,
        menuId: temp[0].value,
      }));
      setMenus(temp);
      await fetchMenuSubmenus({ menuId: temp[0].value });
    }
  }

  async function fetchMenuSubmenus({menuId}) {
    const res = await menuServices.getSubMenus({ menuId });

    if (res?.message != null) {
      toast.error(res.message[0]);
    } else if (res) {
      const temp = res.data.map((item) => {
        return {
          label: item.name,
          value: item.id,
        };
      });

      setProduct((prevState) => ({
        ...prevState,
        submenuId: temp[0].value,
      }));
      setSubmenus(temp);
    }
  }

  async function fetchMenu() {
    const res = await menuServices.gets({
      limit: 100,
      offset: 0,
    });

    if (res?.message != null) {
      toast.error(res.message[0]);
    } else if (res) {
      const temp = res.data.map((item) => {
        return {
          label: item.name,
          value: item.id,
        };
      });

      setProduct((prevState) => ({
        ...prevState,
        menuId: temp[0].value,
      }));
      setMenus(temp);
      await fetchMenuSubmenus({ menuId: temp[0].value });
    }
  }

  async function fetchDigitalProduct() {
    const res = await digitalProductServices.gets();

    if (res?.message != null) {
      toast.error(res.message[0]);
    } else if (res) {
      const temp = res.map((item) => {
        return {
          label: item.name,
          value: item.id,
        };
      });

      setDigitalProducts([
        {
          label: "-",
          value: "-",
        },
        ...temp,
      ]);
    }
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    setProduct((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleFileChange = (e) => {
    const { name, files } = e.target;
    setProduct((prevState) => ({
      ...prevState,
      [name]: files[0],
    }));
    setPreview(URL.createObjectURL(files[0]));
  };

  const handleSubmit = async (e) => {
    setClickAction(true)
    e.preventDefault();

    let tempProduct = {}

    if(product.digitalProductId != "-"){
      if(subscriptionDay == null ) {
        toast.error("Subscription day empty!")
        setClickAction(false)
        return
      }
        tempProduct = {
          ...product,
          additionalData: `{"subscription_time_days": `+ subscriptionDay.toString() + `}`
        }
    } else {
      tempProduct = {
        ...product
      }
    }

    const res = await productsServices.create({ ...tempProduct });

    if (res?.message != null) {
      toast.error(res.message[0]);
    } else if (res) {
      toast.success("Success create product");
      navigate("/product");
    }
  };

  return (
    <div className="col-span-12">
      <form onSubmit={handleSubmit}>
        <div className="grid grid-cols-12 gap-6">
          <div className="col-span-12 sm:col-span-6 bg-white shadow-lg py-8 px-6 rounded-lg h-fit">
            <h4 className="f-h4 text-center">Add Product</h4>
            <br />
            <div className="mt-0">
              <InputDefault
                label="Name"
                name="name"
                value={product.name}
                handleChange={handleChange}
                placeholder=""
                type="text"
                required={true}
              />
            </div>
            <div className="mt-2">
              <InputTextarea
                label="Description"
                name="description"
                value={product.description}
                handleChange={handleChange}
                placeholder=""
                type="text"
                required={true}
                rows={3}
              />
            </div>
            <div className="mt-2">
              <InputDefault
                label="Stock"
                name="stock"
                value={product.stock}
                handleChange={handleChange}
                placeholder=""
                type="number"
                required={true}
              />
            </div>

            <div className="mt-2">
              <InputDefault
                label="Original Price"
                name="originalPrice"
                value={product.originalPrice}
                handleChange={handleChange}
                placeholder=""
                type="number"
                required={true}
              />
            </div>

            <div className="mt-2">
              <InputDefault
                label="Price"
                name="price"
                value={product.price}
                handleChange={handleChange}
                placeholder=""
                type="number"
                required={true}
              />
            </div>
          </div>
          <div className="col-span-12 sm:col-span-6 bg-white shadow-lg py-8 px-6 rounded-lg h-fit">
            <div className="mt-2">
              <InputImage
                label="Image Branch"
                name="displayPic"
                value={product.displayPic}
                preview={preview}
                handleChange={handleFileChange}
                required={true}
              />
            </div>

            {user.userRole == "manager" ? (
              <div className="mt-2">
                <InputSelect
                  label="Tenant"
                  name="tenantId"
                  value={product.tenantId}
                  handleChange={(e) => {
                    handleChange(e);
                    fetchTenantMenus({ tenantId: e.target.value });
                  }}
                  placeholder=""
                  required={true}
                  options={tenants || []}
                />
              </div>
            ) : null}

            <div className="mt-2">
              <InputSelect
                label="Menu"
                name="menuId"
                value={product.menuId}
                handleChange={(e) => {
                  handleChange(e);
                  fetchMenuSubmenus({ menuId: e.target.value });
                }}
                placeholder=""
                required={true}
                options={menus || []}
              />
            </div>

            <div className="mt-2">
              <InputSelect
                label="Sub Menu"
                name="submenuId"
                value={product.submenuId}
                handleChange={handleChange}
                placeholder=""
                required={true}
                options={submenus || []}
              />
            </div>

            <div className="mt-2">
              <InputSelect
                label="Digital Product"
                name="digitalProductId"
                value={product.digitalProductId}
                handleChange={handleChange}
                placeholder=""
                options={
                  digitalProducts || []
                }
              />
            </div>

            <div className="mt-2">
            <InputDefault
                label="Subscription Day"
                name="additionalData"
                value={subscriptionDay}
                handleChange={(e) => {
                  setSubscriptionDay(e.target.value)
                }}
                placeholder=""
                type="number"
              />
            </div>

            <div className="mt-4 flex gap-4">
              <Button
                className="grow"
                color="neutral"
                onClick={() => {
                  navigate("/product");
                }}
                type="button"
              >
                Cancel
              </Button>
              <Button
                disabled={clickAction}
                className="bg-primary-main grow"
                color="neutral"
                type="submit"
              >
                Submit
              </Button>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}
