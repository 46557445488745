import { useState } from "react";
import { Button } from "react-daisyui";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { InputDefault } from "src/components/input/input-default";
import { InputImage } from "src/components/input/input-image";
import { InputSelect } from "src/components/input/input-select";
import { AdvertisesServices } from "src/services/AdvertisesServices";

export function AdvertiseAddPage() {
  const navigate = useNavigate();

  const advertisesServices = new AdvertisesServices();

  const [advertise, setAdvertise] = useState({
    name: "",
    asset: "",
    startDate: new Date().toISOString().split("T")[0],
    endDate: new Date().toISOString().split("T")[0],
    advertiseType: "pop_up",
  });

  const [preview, setPreview] = useState(null);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setAdvertise((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleFileChange = (e) => {
    const { name, files } = e.target;
    setAdvertise((prevState) => ({
      ...prevState,
      [name]: files[0],
    }));
    setPreview(URL.createObjectURL(files[0]));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const res = advertisesServices.create({ ...advertise });

    if (res?.message != null) {
      toast.error(res.message[0]);
    } else if (res) {
      toast.success("Success create advertise");
      navigate("/advertise");
    }
  };

  return (
    <div className="col-span-12">
      <form onSubmit={handleSubmit}>
        <div className="grid grid-cols-12 gap-6">
          <div className="col-span-12 sm:col-span-6 bg-white shadow-lg py-8 px-6 rounded-lg h-fit">
            <h4 className="f-h4 text-center">Add Advertise</h4>
            <br />
            <div className="mt-0">
              <InputDefault
                label="Name Ads"
                name="name"
                value={advertise.name}
                handleChange={handleChange}
                placeholder="Burger Ads"
                type="text"
                required={true}
              />
            </div>
            <div className="mt-2 flex gap-2">
              <div className="grow">
                <InputDefault
                  label="Start Date"
                  name="startDate"
                  value={advertise.startDate}
                  handleChange={handleChange}
                  type="date"
                  required={true}
                />
              </div>
              <div className="grow">
                <InputDefault
                  label="End Date"
                  name="endDate"
                  value={advertise.endDate}
                  handleChange={handleChange}
                  type="date"
                  required={true}
                />
              </div>
            </div>
            <div className="mt-2">
              <InputSelect
                label="Type"
                name="advertiseType"
                placeholder="Pick your type advertise"
                value={advertise.advertiseType}
                handleChange={handleChange}
                required={true}
                options={[
                  { value: "pop_up", label: "Pop Up" },
                  { value: "header", label: "Header" },
                  { value: "splash_screen", label: "Splash Screen" },
                ]}
              />
            </div>
          </div>

          <div className="col-span-12 sm:col-span-6 bg-white shadow-lg py-8 px-6 rounded-lg h-fit">
            <div className="mt-2">
              <InputImage
                label="Image Ads"
                name="asset"
                value={advertise.asset}
                preview={preview}
                handleChange={handleFileChange}
                required={true}
              />
            </div>

            <div className="mt-4 flex gap-4">
              <Button
                className="grow"
                color="neutral"
                onClick={() => {
                  navigate("/advertise");
                }}
                type="button"
              >
                Cancel
              </Button>
              <Button
                className="bg-primary-main grow"
                color="neutral"
                type="submit"
              >
                Submit
              </Button>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}
