import { useContext, useEffect, useState } from "react";
import { Button, Checkbox } from "react-daisyui";
import toast from "react-hot-toast";
import { useNavigate, useParams } from "react-router-dom";
import { InputDefault } from "src/components/input/input-default";
import { InputSelect } from "src/components/input/input-select";
import { MenuServices } from "src/services/MenuServices";
import { SubmenusServices } from "src/services/SubmenusServices";

export function SubMenuEditPage() {
  const navigate = useNavigate();
  const { id } = useParams();

  const submenuServices = new SubmenusServices();
  const menuServices = new MenuServices();

  const [subMenu, setSubMenu] = useState({
    name: "",
    menuId: "",
  });

  const [menus, setMenus] = useState([]);

  useEffect(() => {
    fetch();
  }, []);

  async function fetch() {
    const resMenu = await menuServices.gets({ offset: 0, limit: 100 });
    const resSubmenu = await submenuServices.get({ id: id });

    if (resSubmenu?.message != null) {
      toast.error(resSubmenu.message[0]);
    } else if (resMenu?.message != null) {
      toast.error(resMenu.message[0]);
    } else if (resSubmenu && resMenu) {
      setSubMenu({
        name: resSubmenu.name,
        menuId: resSubmenu.menu.id,
      });

      const temp = resMenu.data.map((item) => {
        return {
          label: item.name,
          value: item.id,
        };
      });

      setMenus(temp);
    }
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    setSubMenu((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const res = await submenuServices.update({ ...subMenu, id });

    if (res?.message != null) {
      toast.error(res.message[0]);
    } else if (res) {
      toast.success("Success update sub menu");
      navigate("/sub-menu");
    }
  };

  return (
    <div className="col-span-12">
      <form onSubmit={handleSubmit}>
        <div className="grid grid-cols-12 gap-6">
          <div className="col-span-12 sm:col-span-6 bg-white shadow-lg py-8 px-6 rounded-lg h-fit">
            <h4 className="f-h4 text-center">Edit Sub Menu</h4>
            <br />
            <div className="mt-0">
              <InputDefault
                label="Name"
                name="name"
                value={subMenu.name}
                handleChange={handleChange}
                placeholder=""
                type="text"
                required={true}
              />
            </div>

            <div className="mt-2">
              <InputSelect
                label="Menu"
                name="menuId"
                value={subMenu.menuId}
                handleChange={handleChange}
                options={menus}
                required={true}
                readonly={true}
              />
            </div>

            <div className="mt-4 flex gap-4">
              <Button
                className="grow"
                color="neutral"
                onClick={() => {
                  navigate("/sub-menu");
                }}
                type="button"
              >
                Cancel
              </Button>
              <Button
                className="bg-primary-main grow"
                color="neutral"
                type="submit"
              >
                Submit
              </Button>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}
