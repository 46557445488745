import axios from "axios";
import { baseUrl } from "src/config/Url";
import { headers, headersFormData } from "./config";
import { handleAxiosError, handleOtherStatusCodes } from "./errors";

export class DigitalProductServices {
  async add({
    name,
    description,
    digitalType,
  }) {

    try {
      const res = await axios.post(`${baseUrl}/dashboard/digital-products`, {
        name,
        description,
        digital_type: digitalType,
      }, {
        headers
      });
      if (res.status === 200 || res.status === 201) {
        return res.data;
      } else {
        handleOtherStatusCodes(res.status);
        return false;
      }
    } catch (err) {
      handleAxiosError(err);
      return false;
    }
  }

  async update({name, description, id}){
    try {
      const res = await axios.patch(`${baseUrl}/dashboard/digital-products/${id}`, {
        name,
        description,
      }, {
        headers
      });
      if (res.status === 200 || res.status === 201) {
        return res.data;
      } else {
        handleOtherStatusCodes(res.status);
        return false;
      }
    } catch (err) {
      handleAxiosError(err);
      return false;
    }
  }

  async gets() {
    try {
      const res = await axios.get(
        `${baseUrl}/dashboard/digital-products`,
        { headers }
      );
      if (res.status === 200) {
        return res.data;
      } else {
        handleOtherStatusCodes(res.status);
        return false;
      }
    } catch (err) {
      handleAxiosError(err);
      return false;
    }
  }

  async get({id}) {
    try {
      const res = await axios.get(
        `${baseUrl}/dashboard/digital-products/${id}`,
        { headers }
      );
      if (res.status === 200) {
        return res.data;
      } else {
        handleOtherStatusCodes(res.status);
        return false;
      }
    } catch (err) {
      handleAxiosError(err);
      return false;
    }
  }

  async delete({ id }) {
    try {
      const res = await axios.delete(`${baseUrl}/dashboard/digital-products/${id}`, {
        headers,
      });
      if (res.status === 200 || res.status === 204) {
        return res.data;
      } else {
        handleOtherStatusCodes(res.status);
        return false;
      }
    } catch (err) {
      handleAxiosError(err);
      return false;
    }
  }

}
