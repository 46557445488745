import { useEffect, useState } from "react";
import { Badge, Button, Form, Input, Pagination, Table } from "react-daisyui";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { ActionIndex } from "src/components/action-index";
import { PaginationComponent } from "src/components/pagination";
import { TableComponent } from "src/components/table";
import { MenuServices } from "src/services/MenuServices";

export function MainMenuPage() {
  const navigate = useNavigate();

  const menuServices = new MenuServices();
  const limit = 20;

  const [menus, setMenus] = useState({
    data: [],
    meta: {
      total: 0,
    },
  });

  const [tempMenus, setTempMenus] = useState({
    data: [],
    meta: {
      total: 0,
    },
  });

  const [offset, setOffset] = useState(0);

  useEffect(() => {
    fetch();
  }, []);

  async function fetch(pOffset) {
    const res = await menuServices.gets({
      limit: limit,
      offset: pOffset ?? offset,
    });

    if (res?.message != null) {
      toast.error(res.message[0]);
    } else if (res) {
      const temp = res.data.map((item) => {
        return {
          id: item.id,
          name: item.name,
          displayPic: item.display_pic,
          isPromotion: item.is_promotion,
        };
      });

      setMenus({
        data: temp,
        meta: {
          total: res.meta.total,
        },
      });

      setTempMenus({
        data: temp,
        meta: {
          total: res.meta.total,
        },
      });
    }
  }

  const headerTable = [
    { code: "id", name: "ID" },
    { code: "name", name: "Name" },
    { code: "displayPic", name: "Image", type: "image" },
    { code: "isPromotion", name: "Promotion", type: "boolean" },
    { code: "action", name: "Action" },
  ];

  const handleSearch = (text) => {
    const filtered = tempMenus.data.filter((item) => {
      return item.name.toLowerCase().includes(text.toLowerCase());
    });

    setMenus({
      data: filtered,
      meta: {
        total: filtered.length,
      },
    });
  };

  return (
    <div className="col-span-12">
      {/* Search Bar */}
      <ActionIndex
        handleSearch={(text) => handleSearch(text)}
        labelButton="Add Main Menu"
        routeActionButton="/main-menu/add"
      />

      <br />

      {/* Tabel */}
      <TableComponent
        header={headerTable}
        action={[
          {
            color: "info",
            name: "Edit",
            callback: (id) => {
              navigate("/main-menu/edit/" + id);
            },
          },
          {
            color: "error",
            name: "Delete",
            callback: async (id) => {
              const res = await menuServices.delete({ id });
              if (res?.message != null) {
                toast.error(res.message[0]);
              } else if (res) {
                fetch();
                toast.success("Success delete menu");
              }
            },
          },
        ]}
        data={menus.data || []}
      />

      <br />

      {/* Pagination */}
      <div className="flex justify-end">
        <PaginationComponent
          active={offset}
          data={Array.from(
            { length: menus?.meta?.total / limit + 1 },
            (_, index) => index + 1
          )}
          callback={(index) => {
            fetch(index * limit);
            setOffset(index);
          }}
        />
      </div>
    </div>
  );
}
