import { useEffect, useState } from "react";
import { Badge, Button, Form, Input, Pagination, Table } from "react-daisyui";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { ActionIndex } from "src/components/action-index";
import { PaginationComponent } from "src/components/pagination";
import { TableComponent } from "src/components/table";
import { AdvertisesServices } from "src/services/AdvertisesServices";

export function AdvertisePage() {
  const navigate = useNavigate();

  const advertisesServices = new AdvertisesServices();
  const limit = 20;

  const [advertises, setAdvertises] = useState({
    data: [],
    meta: {
      total: 0,
    },
  });

  const [tempAdvertises, setTempAdvertises] = useState({
    data: [],
    meta: {
      total: 0,
    },
  });

  const [offset, setOffset] = useState(0);

  useEffect(() => {
    fetch();
  }, []);

  async function fetch(pOffset) {
    const res = await advertisesServices.gets({
      limit: limit,
      offset: pOffset ?? offset,
    });

    if (res?.message != null) {
      toast.error(res.message[0]);
    } else if (res) {
      const temp = res.data.map((item) => {
        return {
          id: item.id,
          name: item.name,
          asset: item.asset,
          startDate: item.start_date,
          endDate: item.end_date,
          advertiseType: item.advertise_type,
        };
      });

      setAdvertises({
        data: temp,
        meta: {
          total: res.meta.total,
        },
      });

      setTempAdvertises({
        data: temp,
        meta: {
          total: res.meta.total,
        },
      });
    }
  }

  const headerTable = [
    { code: "id", name: "ID" },
    { code: "advertiseType", name: "Type" },
    // { code: "name", name: "Name Ads" },
    // { code: "asset", name: "Asset" },
    { code: "startDate", name: "Start Date" },
    { code: "endDate", name: "End Date" },
    { code: "action", name: "Action" },
  ];

  const handleSearch = (text) => {
    const filtered = tempAdvertises.data.filter((item) => {
      return item.id.toLowerCase().includes(text.toLowerCase());
    });

    setAdvertises({
      data: filtered,
      meta: {
        total: filtered.length,
      },
    });
  };

  return (
    <div className="col-span-12">
      {/* Search Bar */}
      <ActionIndex
        handleSearch={(text) => handleSearch(text)}
        labelButton="Add Advertise"
        routeActionButton="/advertise/add"
      />

      <br />

      {/* Tabel */}
      <TableComponent
        header={headerTable}
        action={[
          {
            color: "info",
            name: "Edit",
            callback: (id) => {
              navigate("/advertise/edit/" + id);
            },
          },
          {
            color: "error",
            name: "Delete",
            callback: async (id) => {
              const res = await advertisesServices.delete({ id });
              if (res?.message != null) {
                toast.error(res.message[0]);
              } else if (res) {
                fetch();
                toast.success("Success delete advertise");
              }
            },
          },
        ]}
        data={advertises.data || []}
      />

      <br />

      {/* Pagination */}
      <div className="flex justify-end">
        <PaginationComponent
          active={offset}
          data={Array.from(
            { length: advertises?.meta?.total / limit + 1 },
            (_, index) => index + 1
          )}
          callback={(index) => {
            fetch(index * limit);
            setOffset(index);
          }}
        />
      </div>
    </div>
  );
}
