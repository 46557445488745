import imageComingSoon from "src/assets/ilustration/coming-soon.svg";

export function HomePage() {
  return (
    <div className="col-span-12">
      <div className="shadow-s1 flex justify-center items-center rounded-lg py-24 px-24">
        <img src={imageComingSoon} />
      </div>
    </div>
  );
}
