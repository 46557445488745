// var convert = require("xml-js");
// export async function parseXMLToJSON(xmlData) {
//   var xml2 =
//     '<?xml version="1.0" encoding="utf-8"?>' +
//     '<note importance="high" logged="true">' +
//     "    <title>Happy</title>" +
//     "    <todo>Work</todo>" +
//     "    <todo>Play</todo>" +
//     "</note>";

//   return convert.xml2json(xml2);
// }

var XMLParser = require("react-xml-parser");
export async function parseXMLToJSON(xmlData) {
  var xml2 =
    '<?xml version="1.0" encoding="utf-8"?>' +
    '<note importance="high" logged="true">' +
    "    <title>Happy</title>" +
    "    <todo>Work</todo>" +
    "    <todo>Play</todo>" +
    "</note>";

  const xml = await new XMLParser().parseFromString(xml2);
  return xml;
}
