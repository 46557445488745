import { useState } from "react";
import { Button } from "react-daisyui";
import { useNavigate } from "react-router-dom";
import { InputDefault } from "src/components/input/input-default";

export function TaxAddPage() {
  const navigate = useNavigate();
  const [tax, setTax] = useState({
    name: "",
    value: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setTax((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {};

  return (
    <div className="col-span-12">
      <form onSubmit={handleSubmit}>
        <div className="grid grid-cols-12 gap-6">
          <div className="col-span-12 sm:col-span-6 bg-white shadow-lg py-8 px-6 rounded-lg h-fit">
            <h4 className="f-h4 text-center">Add Tax</h4>
            <br />
            <div className="mt-0">
              <InputDefault
                label="Name"
                name="name"
                placeholder="PPn Tax"
                value={tax.name}
                handleChange={handleChange}
                type="text"
                required={true}
              />
            </div>
            <div className="mt-2">
              <InputDefault
                label="Tax per product"
                name="value"
                placeholder="15%"
                value={tax.value}
                handleChange={handleChange}
                type="text"
                required={true}
              />
            </div>
            <div className="mt-4 flex gap-4">
              <Button
                className="grow"
                color="neutral"
                onClick={() => {
                  navigate("/tax");
                }}
                type="button"
              >
                Cancel
              </Button>
              <Button
                className="bg-primary-main grow"
                color="neutral"
                onClick={() => {}}
              >
                Submit
              </Button>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}
