import "./App.css";
import React from "react";
import { useContext, useEffect, useState } from "react";
import { AppContextProvider } from "./context/AppContextProvider";
import { UserContext } from "./context/UserContext";
import { QueryClient, QueryClientProvider, useQuery } from "react-query";
import LoadComponent from "./components/load";
import AuthRouterPage from "./router/AuthRouterPage";
import OwnerRouterPage from "./router/OwnerRouterPage";
import ManagerRouterPage from "./router/ManagerRouterPage";
import TenantRouterPage from "./router/TenantRouterPage";
import { JWTPayload } from "./methods/JWTPayload";
import { cookies } from "./services/config";
import { UsersServices } from "./services/UsersServices";

const queryClient = new QueryClient();

function App() {
  // console.log(process.env.REACT_APP_API_URL);

  return (
    <QueryClientProvider client={queryClient}>
      <AppContextProvider>
        <UserManager />
      </AppContextProvider>
    </QueryClientProvider>
  );
}

export default App;

function UserManager() {
  const userServices = new UsersServices();
  const { user, setUser } = useContext(UserContext);
  const [load, setLoad] = useState(true);

  useEffect(() => {
    fetch();
  }, []);

  // async function fetch() {
  //   const payload = JWTPayload(cookies?.token ?? "");
  //   console.log(payload);

  //   if (payload) {
  //     if (payload?.exp < Date.now() / 1000) {
  //       document.cookie = "token=; Max-Age=0";
  //       setUser(null);
  //     } else if (payload?.exp < Date.now() / 1000 + 300000) {
  //       const res = await userServices.refreshToken();
  //       const newPayload = JWTPayload(res.token);
  //       document.cookie = `token=${res.token}`;
  //       if (newPayload) {
  //         setUser({
  //           name: newPayload.name,
  //           email: newPayload.email,
  //           userRole: newPayload.user_role,
  //         });
  //       }
  //     } else {
  //       setUser({
  //         name: payload.name,
  //         email: payload.email,
  //         userRole: payload.user_role,
  //       });
  //     }
  //   } else {
  //     setUser(null);
  //   }
  //   setLoad(false);
  // }

  async function fetch() {
    const payload = JWTPayload(cookies?.token ?? "");
    const nextExp = payload.exp * 1000;

    if (nextExp / 1000 < new Date().getTime() / 1000) {
      document.cookie = "token=; Max-Age=0";

      setUser(null);
      window.location.href = "/";
    }

    if (payload) {
      setUser({
        name: payload.name,
        email: payload.email,
        userRole: payload.user_role,
      });
    }
    setLoad(false);
  }

  if (load) {
    return (
      <div className="w-full h-screen flex justify-center items-center bg-white">
        <LoadComponent />
      </div>
    );
  } else if (user == null && load == false) {
    return <AuthRouterPage />;
  } else if (user.userRole === "owner") {
    return <OwnerRouterPage />;
  } else if (user.userRole === "manager") {
    return <ManagerRouterPage />;
  } else if (user.userRole === "tenant") {
    return <TenantRouterPage />;
  } else {
    return <h1>404 Page</h1>;
  }
}
