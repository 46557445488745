import axios from "axios";
import { baseUrl } from "src/config/Url";
import { headers, headersFormData } from "./config";
import { handleAxiosError, handleOtherStatusCodes } from "./errors";

export class TenantsServices {
  async create({
    name,
    image,
    operatorName,
    operatorPassword,
    operatorPhone,
    operatorEmail,
  }) {
    const formData = new FormData();
    formData.append("name", name);
    formData.append("image", image);
    formData.append("operator_name", operatorName);
    formData.append("operator_password", operatorPassword);
    formData.append("operator_phone", operatorPhone);
    formData.append("operator_email", operatorEmail);

    try {
      const res = await axios.post(`${baseUrl}/dashboard/tenants`, formData, {
        headers: headersFormData,
      });
      if (res.status === 200) {
        return res.data;
      } else {
        handleOtherStatusCodes(res.status);
        return false;
      }
    } catch (err) {
      handleAxiosError(err);
      return false;
    }
  }

  async update({
    name,
    operatorName,
    operatorPassword,
    operatorPhone,
    operatorEmail,
    image,
    id,
  }) {
    const formData = new FormData();
    formData.append("name", name);
    formData.append("operator_name", operatorName);
    formData.append("operator_password", operatorPassword);
    formData.append("operator_phone", operatorPhone);
    formData.append("operator_email", operatorEmail);

    if (image != null) formData.append("image", image);

    try {
      const res = await axios.patch(
        `${baseUrl}/dashboard/tenants/${id}`,
        formData,
        {
          headers: headersFormData,
        }
      );
      if (res.status === 200) {
        return res.data;
      } else {
        handleOtherStatusCodes(res.status);
        return false;
      }
    } catch (err) {
      handleAxiosError(err);
      return false;
    }
  }

  async get({ id }) {
    try {
      const res = await axios.get(`${baseUrl}/dashboard/tenants/${id}`, {
        headers,
      });

      if (res.status === 200) {
        return res.data;
      } else {
        handleOtherStatusCodes(res.status);
        return false;
      }
    } catch (err) {
      handleAxiosError(err);
      return false;
    }
  }

  async gets({ limit = 20, offset = 0 }) {
    try {
      const res = await axios.get(
        `${baseUrl}/dashboard/tenants?limit=${limit}&offset=${offset}`,
        { headers }
      );
      if (res.status === 200) { 
        return res.data;
      } else {
        handleOtherStatusCodes(res.status);
        return false;
      }
    } catch (err) {
      handleAxiosError(err);
      return false;
    }
  }

  async getMenus({ tenantId }) {
    try {
      const res = await axios.get(
        `${baseUrl}/dashboard/tenants/${tenantId}/menus`,
        { headers }
      );
      if (res.status === 200) {
        return res.data;
      } else {
        handleOtherStatusCodes(res.status);
        return false;
      }
    } catch (err) {
      handleAxiosError(err);
      return false;
    }
  }

  async delete({ id }) {
    try {
      const res = await axios.delete(`${baseUrl}/dashboard/tenants/${id}`, {
        headers,
      });
      if (res.status === 200) {
        return res.data;
      } else {
        handleOtherStatusCodes(res.status);
        return false;
      }
    } catch (err) {
      handleAxiosError(err);
      return false;
    }
  }
}
