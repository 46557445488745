import axios from "axios";
import { baseUrl } from "src/config/Url";
import { headers, headersFormData } from "./config";
import { handleAxiosError, handleOtherStatusCodes } from "./errors";

export class MembersServices {
  async registration({
    name,
    phone,
    password,
    email,
    photo,
    national_id_number,
  }) {
    const formData = new FormData();
    formData.append("name", name);
    formData.append("phone", phone);
    formData.append("password", password);
    formData.append("email", email);
    formData.append("photo", photo);
    formData.append("national_id_number", national_id_number);

    try {
      const res = await axios.post(`${baseUrl}/dashboard/members`, formData, {
        headers: headersFormData,
      });
      if (res.status === 200) {
        return res.data;
      } else {
        handleOtherStatusCodes(res.status);
        return false;
      }
    } catch (err) {
      handleAxiosError(err);
      return false;
    }
  }

  async update({
    name,
    photo,
    national_id_number,
    id
  }) {
    const formData = new FormData();
    formData.append("name", name);
    if (photo != null) formData.append("photo", photo);
    if (national_id_number != null) formData.append("national_id_number", national_id_number);

    try {
      const res = await axios.patch(`${baseUrl}/dashboard/members/${id}`, formData, {
        headers: headersFormData,
      });
      if (res.status === 200) {
        return res.data;
      } else {
        handleOtherStatusCodes(res.status);
        return false;
      }
    } catch (err) {
      handleAxiosError(err);
      return false;
    }
  }

  async get({ id }) {
    try {
      const res = await axios.get(`${baseUrl}/dashboard/members/${id}`, {
        headers,
      });

      if (res.status === 200) {
        return res.data;
      } else {
        handleOtherStatusCodes(res.status);
        return false;
      }
    } catch (err) {
      handleAxiosError(err);
      return false;
    }
  }

  async gets({ limit = 20, offset = 0 }) {
    try {
      const res = await axios.get(
        `${baseUrl}/dashboard/members?limit=${limit}&offset=${offset}`,
        { headers }
      );
      if (res.status === 200) {
        return res.data;
      } else {
        handleOtherStatusCodes(res.status);
        return false;
      }
    } catch (err) {
      handleAxiosError(err);
      return false;
    }
  }

  async delete({ id }) {
    try {
      const res = await axios.delete(`${baseUrl}/dashboard/members/${id}`, {
        headers,
      });
      if (res.status === 200) {
        return res.data;
      } else {
        handleOtherStatusCodes(res.status);
        return false;
      }
    } catch (err) {
      handleAxiosError(err);
      return false;
    }
  }

  async addMemberCard({ cardNumber, id }) {
    try {
      const res = await axios.post(
        `${baseUrl}/dashboard/members/${id}/cards`,
        {
          card_number: cardNumber,
        },
        {
          headers,
        }
      );
      if (res.status === 200) {
        return res.data;
      } else {
        handleOtherStatusCodes(res.status);
        return false;
      }
    } catch (err) {
      handleAxiosError(err);
      return false;
    }
  }
}
