import { useContext, useState } from "react";
import { Button } from "react-daisyui";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { InputDefault } from "src/components/input/input-default";
import { InputSelect } from "src/components/input/input-select";
import { UserContext } from "src/context/UserContext";
import { OperationalCostServices } from "src/services/OperationalCostServices";
import { TenantsServices } from "src/services/TenantsServices";

export function OperationalAddPage() {
  const navigate = useNavigate();

  const operationalCostServices = new OperationalCostServices();
  const tenantsServices = new TenantsServices();

  const { user } = useContext(UserContext);

  const [offset, setOffset] = useState(0);

  const [tenants, setTenants] = useState([]);

  const [operational, setOperational] = useState({
    name: "",
    costDate: new Date().toISOString().split("T")[0],
    taxAmount: "",
    amount: "",
    tenantId: "",
  });

  useState(() => {
    if (user.userRole == "manager") fetch();
  }, []);

  async function fetch(pOffset) {
    const res = await tenantsServices.gets({
      limit: 100,
      offset: pOffset ?? offset,
    });

    if (res?.message != null) {
      toast.error(res.message[0]);
    } else if (res) {
      const temp = res.data.map((item) => {
        return {
          value: item.id,
          label: item.name,
        };
      });

      setOperational((prevState) => ({
        ...prevState,
        tenantId: temp[0].value,
      }));

      setTenants(temp);
    }
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    setOperational((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const res = await operationalCostServices.create({ ...operational });

    if (res?.message != null) {
      toast.error(res.message[0]);
    } else if (res) {
      toast.success("Success create operational cost");
      navigate("/operational");
    }
  };

  return (
    <div className="col-span-12">
      <form onSubmit={handleSubmit}>
        <div className="grid grid-cols-12 gap-6">
          <div className="col-span-12 sm:col-span-6 bg-white shadow-lg py-8 px-6 rounded-lg h-fit">
            <h4 className="f-h4 text-center">Add Operational</h4>
            <br />
            <div className="mt-0">
              <InputDefault
                label="Name"
                name="name"
                value={operational.name}
                handleChange={handleChange}
                type="text"
                required={true}
                placeholder=""
              />
            </div>
            <div className="mt-2">
              <InputDefault
                label="Date"
                name="costDate"
                value={operational.costDate}
                handleChange={handleChange}
                type="date"
                required={true}
                placeholder=""
              />
            </div>
            <div className="mt-2">
              <InputDefault
                label="Tax"
                name="taxAmount"
                value={operational.taxAmount}
                handleChange={handleChange}
                type="text"
                required={true}
              />
            </div>
            <div className="mt-2">
              <InputDefault
                label="Amount"
                name="amount"
                value={operational.amount}
                handleChange={handleChange}
                type="text"
                required={true}
              />
            </div>
            {user.userRole == "manager" ? (
              <div className="mt-2">
                <InputSelect
                  label="Tenant"
                  name="tenantId"
                  value={operational.tenantId}
                  handleChange={handleChange}
                  options={tenants}
                  required={true}
                />
              </div>
            ) : null}
            <div className="mt-4 flex gap-4">
              <Button
                className="grow"
                color="neutral"
                onClick={() => {
                  navigate("/operational");
                }}
                type="button"
              >
                Cancel
              </Button>
              <Button
                className="bg-primary-main grow"
                color="neutral"
                type="submit"
              >
                Submit
              </Button>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}
