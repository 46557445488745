import { Avatar, Button, Stats } from "react-daisyui";
import { FiDollarSign } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import { StatsDefaultComponent } from "src/components/stats/stats-default";

export function TaxPage() {
  const navigate = useNavigate();

  return (
    <div className="col-span-12 grid grid-cols-4 gap-4">
      <StatsDefaultComponent
        icon={<FiDollarSign size={24} />}
        title="PPn Tax"
        value="15%"
        actions={() => {}}
      />
      <StatsDefaultComponent
        icon={<FiDollarSign size={24} />}
        title="PPn Tax"
        value="15%"
        actions={() => {}}
      />
      <StatsDefaultComponent
        icon={<FiDollarSign size={24} />}
        title="PPn Tax"
        value="15%"
        actions={() => {}}
      />

      <Button
        onClick={() => {
          navigate("/tax/add");
        }}
        color="neutral"
        className="bg-primary-main col-span-4 fixed bottom-10 right-10"
      >
        Add Tax
      </Button>
    </div>
  );
}
