import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { DashboardLayout } from "src/layouts/dahsboard";
import { HomePage } from "src/pages/home";
import { MainMenuPage } from "src/pages/main-menu";
import { MainMenuAddPage } from "src/pages/main-menu/add";
import { MainMenuEditPage } from "src/pages/main-menu/edit";
import { OperationalPage } from "src/pages/operational";
import { OperationalAddPage } from "src/pages/operational/add";
import { OperationalEditPage } from "src/pages/operational/edit";
import { OrderPage } from "src/pages/order";
import { OrderDetailPage } from "src/pages/order/detail";
import { ProductPage } from "src/pages/product";
import { ProductAddPage } from "src/pages/product/add";
import { ProductEditPage } from "src/pages/product/edit";
import { ProfilePage } from "src/pages/profile";
import { ProfileEditPage } from "src/pages/profile/edit";
import { SubMenuPage } from "src/pages/sub-menu";
import { SubMenuAddPage } from "src/pages/sub-menu/add";
import { SubMenuEditPage } from "src/pages/sub-menu/edit";
import { TransactionPage } from "src/pages/transaction";
import { BillPage } from "src/pages/transaction/bill";

export default function TenantRouterPage() {
  return (
    <BrowserRouter>
      <DashboardLayout>
        <Routes>
          <Route path="/" element={<HomePage />} />

          <Route path="/transaction" element={<TransactionPage />} />
          <Route path="/transaction/:id" element={<BillPage />} />

          <Route path="/operational" element={<OperationalPage />} />
          <Route path="/operational/add" element={<OperationalAddPage />} />
          <Route
            path="/operational/edit/:id"
            element={<OperationalEditPage />}
          />

          <Route path="/main-menu" element={<MainMenuPage />} />
          <Route path="/main-menu/add" element={<MainMenuAddPage />} />
          <Route path="/main-menu/edit/:id" element={<MainMenuEditPage />} />

          <Route path="/sub-menu" element={<SubMenuPage />} />
          <Route path="/sub-menu/add" element={<SubMenuAddPage />} />
          <Route path="/sub-menu/edit/:id" element={<SubMenuEditPage />} />

          <Route path="/product" element={<ProductPage />} />
          <Route path="/product/add" element={<ProductAddPage />} />
          <Route path="/product/edit/:id" element={<ProductEditPage />} />

          <Route path="/order-report" element={<OrderPage />} />
          <Route
            path="/order-report/detail/:id"
            element={<OrderDetailPage />}
          />

          <Route path="/profile/me" element={<ProfilePage />} />
          <Route path="/profile/me/edit" element={<ProfileEditPage />} />

          <Route path="*" element={<HomePage />} exact />
        </Routes>
      </DashboardLayout>
    </BrowserRouter>
  );
}
