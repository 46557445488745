import { useContext, useEffect, useState } from "react";
import toast from "react-hot-toast";
import { ProfilesServices } from "src/services/ProfilesServices";
import iconAvatar from "src/assets/icon/avatar.svg";
import { UserContext } from "src/context/UserContext";
import { Button } from "react-daisyui";
import { useNavigate } from "react-router-dom";

export function ProfilePage() {
  const navigate = useNavigate();
  const profileServices = new ProfilesServices();

  const { user } = useContext(UserContext);

  const [profile, setProfile] = useState({
    id: "",
    name: "",
    email: "",
    phone: "",
    tenant: {
      id: "",
      name: "",
      branchId: "",
    },
  });

  useEffect(() => {
    fetch();
  }, []);

  async function fetch() {
    const res = await profileServices.get();

    if (res?.message != null) {
      toast.error(res.message[0]);
    } else if (res) {
      const temp = {
        id: res.id,
        name: res.name,
        email: res.email,
        phone: res.phone,
        tenant: {
          id: res.tenant?.id || "",
          name: res.tenant?.name || "",
          branchId: res.tenant?.branch_id || "",
        },
      };
      setProfile(temp);
    }
  }

  return (
    <div className="col-span-12">
      <div className="bg-white rounded-xl p-5 shadow-s3">
        <h4 className="f-h4">My Profile</h4>
        <div className="border border-gray-400 rounded-xl flex items-center gap-5 p-5 mt-2">
          <div className="rounded-full w-16 h-16 overflow-hidden">
            <img src={iconAvatar} />
          </div>
          <div className="flex flex-col gap-1">
            <h5 className="f-h5 font-bold">{profile?.name}</h5>
            <p className="f-p2-r">{profile?.email}</p>
            <p className="f-p2-r">{profile?.phone || "-"}</p>
          </div>
        </div>
        {user.userRole == "tenant" ? (
          <div className="border border-gray-400 rounded-xl flex items-center gap-5 p-5 mt-2">
            <div className="flex flex-col gap-1">
              <h4 className="f-h4 font-bold">Tenant</h4>
              <div className="grid grid-cols-12 gap-3">
                <h6 className="f-h5 lg:col-span-2 col-span-6">ID</h6>
                <p className="f-p2-r lg:col-span-10 col-span-6">
                  {profile?.tenant?.id}
                </p>
              </div>
              <div className="grid grid-cols-12 gap-3">
                <h6 className="f-h5 lg:col-span-2 col-span-6">Name</h6>
                <p className="f-p2-r lg:col-span-10 col-span-6">
                  {profile?.tenant?.name}
                </p>
              </div>
              <div className="grid grid-cols-12 gap-3">
                <h6 className="f-h5 lg:col-span-2 col-span-6">Branch ID</h6>
                <p className="f-p2-r lg:col-span-10 col-span-6">
                  {profile?.tenant?.branchId}
                </p>
              </div>
            </div>
          </div>
        ) : null}

        <div className="mt-2">
          <Button
            onClick={() => {
              navigate("/profile/me/edit");
            }}
            className="btn btn-primary"
          >
            Edit Profile
          </Button>
        </div>
      </div>
    </div>
  );
}
