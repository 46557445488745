import { useEffect, useState } from "react";
import { Badge, Button, Form, Input, Pagination, Table } from "react-daisyui";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { ActionIndex } from "src/components/action-index";
import { PaginationComponent } from "src/components/pagination";
import { TableComponent } from "src/components/table";
import { BrandInterfaceServices } from "src/services/BrandInterfaceServices";

export function BrandInterfacePage() {
  const navigate = useNavigate();

  const brandInterfaceServices = new BrandInterfaceServices();

  const [brandInterface, setBrandInterface] = useState(null);

  // const [brandInterface, setBrandInterface] = useState({
  //   logo: "",
  //   mainColor: "",
  //   secondaryColor: "",
  //   gradientColor: "",
  //   primaryImage: "",
  //   secondaryImage: "",
  //   tertiaryImage: "",
  // });

  useEffect(() => {
    fetch();
  }, []);

  async function fetch(pOffset) {
    const res = await brandInterfaceServices.get();

    if (res?.message != null) {
      toast.error(res.message[0]);
    } else if (res) {
      const temp = {
        logo: res.logo,
        mainColor: res.main_color,
        secondaryColor: res.secondary_color,
        gradientColor: res.gradient_color,
        primaryImage: res.primary_image,
        secondaryImage: res.secondary_image,
        tertiaryImage: res.tertiary_image,
      };
      setBrandInterface(temp);
    }
  }

  return (
    <div className="col-span-12">
      {brandInterface == null ? (
        <div className="bg-white rounded-xl p-5 gap-3 flex items-center shadow-s4 justify-between">
          <p>Interface not already setup</p>

          <Button
            onClick={() => navigate("/brand-interface/add")}
            color="primary"
            size="sm"
          >
            Setup Interface
          </Button>
        </div>
      ) : (
        <div>
          <Button
            onClick={() => navigate("/brand-interface/add")}
            color="primary"
          >
            Setup Interface
          </Button>

          <br />
          <div className="">
            <img src={brandInterface.logo} alt="Logo Brand" />
          </div>
          <br />
          <div className="flex gap-4">
            <div className="bg-white rounded-xl p-5 gap-3 flex items-center shadow-s4">
              <div
                className="h-12 w-12 rounded-full"
                style={{ backgroundColor: brandInterface.mainColor }}
              ></div>
              <div className="flex flex-col gap-1">
                <h4>Main Color</h4>
                <h4>{brandInterface.mainColor}</h4>
              </div>
            </div>
            <div className="bg-white rounded-xl p-5 gap-3 flex items-center shadow-s4">
              <div
                className="h-12 w-12 rounded-full"
                style={{ backgroundColor: brandInterface.secondaryColor }}
              ></div>
              <div className="flex flex-col gap-1">
                <h4>Secondary Color</h4>
                <h4>{brandInterface.secondaryColor}</h4>
              </div>
            </div>
            <div className="bg-white rounded-xl p-5 gap-3 flex items-center shadow-s4">
              <div
                className="h-12 w-12 rounded-full"
                style={{ backgroundColor: brandInterface.gradientColor }}
              ></div>
              <div className="flex flex-col gap-1">
                <h4>Gradient Color</h4>
                <h4>{brandInterface.gradientColor}</h4>
              </div>
            </div>
          </div>

          <br />

          <div className="flex gap-4">
            <img src={brandInterface.primaryImage} alt="Primary Image" />
            <img src={brandInterface.secondaryImage} alt="Secondary Image" />
            <img src={brandInterface.tertiaryImage} alt="Tertiary Image" />
          </div>
        </div>
      )}
    </div>
  );
}
