import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useNavigate, useParams } from "react-router-dom";
import { TableComponent } from "src/components/table";
import { OrdersServices } from "src/services/OrdersServices";

export function OrderDetailPage() {
  const navigate = useNavigate();
  const { id } = useParams();

  const orderServices = new OrdersServices();

  const [order, setOrder] = useState([]);

  useEffect(() => {
    fetch();
  }, []);

  async function fetch() {
    const res = await orderServices.get({
      id,
    });

    if (res?.message != null) {
      toast.error(res.message[0]);
    } else if (res) {
      const temp = [
        {
          id: res.id,
          customerName: res.customer_name ?? "Guest",
          nettAmount: res.nett_amount ?? "-",
          orderType: res.order_type ?? "-",
          tableNumber: res.table_number ?? "-",
          totalAmount: res.total_amount ?? "-",
          orderItems: res.order_items.map((item) => {
            return {
              id: item.id,
              productDescription: item.product_description,
              productName: item.product_name,
              productPrice: item.product_price,
              qty: item.qty,
              totalAmount: item.total_amount,
            };
          }),
        },
      ];
      setOrder(temp);
      console.log(temp);
    }
  }

  const headerMainTable = [
    { code: "id", name: "ID" },
    { code: "customerName", name: "Customer Name" },
    { code: "nettAmount", name: "Nett Amount" },
    { code: "orderType", name: "Order Type" },
    { code: "tableNumber", name: "Table Number" },
    { code: "totalAmount", name: "Total Amount" },
  ];

  const headerSubTable = [
    { code: "id", name: "ID" },
    { code: "productDescription", name: "Product Description" },
    { code: "productName", name: "Product Name" },
    { code: "productPrice", name: "Product Price" },
    { code: "qty", name: "Qty" },
    { code: "totalAmount", name: "Total Amount" },
  ];

  return (
    <div className="col-span-12">
      <br />

      <h4 className="f-h4 mb-2">Order Detail</h4>
      {/* Tabel */}
      <TableComponent header={headerMainTable} data={order || []} />

      <br />

      <h4 className="f-h4 mb-2">Order Items</h4>
      {/* Tabel */}
      <TableComponent
        header={headerSubTable}
        data={order[0]?.orderItems ?? []}
      />

      <br />
    </div>
  );
}
