import axios from "axios";
import { baseUrl } from "src/config/Url";
import { headers, headersFormData } from "./config";
import { handleAxiosError, handleOtherStatusCodes } from "./errors";

export class MenuServices {
  async create({ name, isPromotion, displayPic, tenantId }) {
    const formData = new FormData();
    formData.append("name", name);
    formData.append("is_promotion", isPromotion);
    formData.append("display_pic", displayPic);
    formData.append("tenant_id", tenantId);

    try {
      const res = await axios.post(`${baseUrl}/dashboard/menus`, formData, {
        headers: headersFormData,
      });
      if (res.status === 200) {
        return res.data;
      } else {
        handleOtherStatusCodes(res.status);
        return false;
      }
    } catch (err) {
      handleAxiosError(err);
      return false;
    }
  }

  async update({ name, isPromotion, id, displayPic }) {
    const formData = new FormData();
    formData.append("name", name);
    formData.append("is_promotion", isPromotion);

    if (displayPic) {
      formData.append("display_pic", displayPic);
    }

    try {
      const res = await axios.patch(
        `${baseUrl}/dashboard/menus/${id}`,
        formData,
        {
          headers: headersFormData,
        }
      );
      if (res.status === 200) {
        return res.data;
      } else {
        handleOtherStatusCodes(res.status);
        return false;
      }
    } catch (err) {
      handleAxiosError(err);
      return false;
    }
  }

  async get({ id }) {
    try {
      const res = await axios.get(`${baseUrl}/dashboard/menus/${id}`, {
        headers,
      });

      if (res.status === 200) {
        return res.data;
      } else {
        handleOtherStatusCodes(res.status);
        return false;
      }
    } catch (err) {
      handleAxiosError(err);
      return false;
    }
  }

  async gets({ limit = 20, offset = 0, tenantId }) {
    try {
      const res = await axios.get(
        `${baseUrl}/dashboard/menus?limit=${limit}&offset=${offset}&tenant_id=${tenantId}`,
        { headers }
      );
      if (res.status === 200) {
        return res.data;
      } else {
        handleOtherStatusCodes(res.status);
        return false;
      }
    } catch (err) {
      handleAxiosError(err);
      return false;
    }
  }

  async getSubMenus({ menuId }) {
    try {
      const res = await axios.get(
        `${baseUrl}/dashboard/menus/${menuId}/submenus`,
        { headers }
      );
      if (res.status === 200) {
        return res.data;
      } else {
        handleOtherStatusCodes(res.status);
        return false;
      }
    } catch (err) {
      handleAxiosError(err);
      return false;
    }
  }

  async delete({ id }) {
    try {
      const res = await axios.delete(`${baseUrl}/dashboard/menus/${id}`, {
        headers,
      });
      if (res.status === 200) {
        return res.data;
      } else {
        handleOtherStatusCodes(res.status);
        return false;
      }
    } catch (err) {
      handleAxiosError(err);
      return false;
    }
  }
}
