import { useEffect, useState } from "react";
import { Button } from "react-daisyui";
import toast from "react-hot-toast";
import { useNavigate, useParams } from "react-router-dom";
import { InputDefault } from "src/components/input/input-default";
import { OperationalCostServices } from "src/services/OperationalCostServices";

export function OperationalEditPage() {
  const navigate = useNavigate();
  const { id } = useParams();

  const operationalCostServices = new OperationalCostServices();

  const [operational, setOperational] = useState({
    name: "",
    costDate: "",
    taxAmount: "",
    amount: "",
    totalAmount: "",
  });

  useEffect(() => {
    fetch();
  }, []);

  async function fetch() {
    const res = await operationalCostServices.get({ id: id });

    if (res?.message != null) {
      toast.error(res.message[0]);
    } else if (res) {
      setOperational({
        name: res.name,
        costDate: res.cost_date,
        taxAmount: res.tax_amount,
        amount: res.amount,
        totalAmount: res.total_amount,
      });
    }
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    setOperational((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const res = await operationalCostServices.update({ ...operational, id });

    if (res?.message != null) {
      toast.error(res.message[0]);
    } else if (res) {
      toast.success("Success edit operational cost");
      navigate("/operational");
    }
  };

  return (
    <div className="col-span-12">
      <form onSubmit={handleSubmit}>
        <div className="grid grid-cols-12 gap-6">
          <div className="col-span-12 sm:col-span-6 bg-white shadow-lg py-8 px-6 rounded-lg h-fit">
            <h4 className="f-h4 text-center">Edit Operational</h4>
            <br />
            <div className="mt-0">
              <InputDefault
                label="Name"
                name="name"
                value={operational.name}
                handleChange={handleChange}
                type="text"
                required={true}
                placeholder=""
              />
            </div>
            <div className="mt-2">
              <InputDefault
                label="Date"
                name="costDate"
                value={operational.costDate}
                handleChange={handleChange}
                type="date"
                required={true}
                placeholder=""
              />
            </div>
            <div className="mt-2">
              <InputDefault
                label="Tax"
                name="taxAmount"
                value={operational.taxAmount}
                handleChange={handleChange}
                type="text"
                required={true}
              />
            </div>
            <div className="mt-2">
              <InputDefault
                label="Amount"
                name="amount"
                value={operational.amount}
                handleChange={handleChange}
                type="text"
                required={true}
              />
            </div>

            <div className="mt-4 flex gap-4">
              <Button
                className="grow"
                color="neutral"
                onClick={() => {
                  navigate("/operational");
                }}
                type="button"
              >
                Cancel
              </Button>
              <Button
                className="bg-primary-main grow"
                color="neutral"
                type="submit"
              >
                Submit
              </Button>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}
