import axios from "axios";
import { baseUrl } from "src/config/Url";
import { headers, headersFormData } from "./config";
import { handleAxiosError, handleOtherStatusCodes } from "./errors";

export class BrandInterfaceServices {
  async create({
    logo,
    mainColor,
    secondaryColor,
    gradientColor,
    primaryImage,
    secondaryImage,
    tertiaryImage,
  }) {
    const formData = new FormData();
    formData.append("logo", logo);
    formData.append("main_color", mainColor);
    formData.append("secondary_color", secondaryColor);
    formData.append("gradient_color", gradientColor);
    formData.append("primary_image", primaryImage);
    formData.append("secondary_image", secondaryImage);
    formData.append("tertiary_image", tertiaryImage);

    try {
      const res = await axios.post(
        `${baseUrl}/dashboard/brand-interfaces`,
        formData,
        {
          headers: headersFormData,
        }
      );
      if (res.status === 200) {
        return res.data;
      } else {
        handleOtherStatusCodes(res.status);
        return false;
      }
    } catch (err) {
      handleAxiosError(err);
      return false;
    }
  }

  async update({
    logo,
    mainColor,
    secondaryColor,
    gradientColor,
    primaryImage,
    secondaryImage,
    tertiaryImage,
  }) {
    const formData = new FormData();
    formData.append("logo", logo);
    formData.append("main_color", mainColor);
    formData.append("secondary_color", secondaryColor);
    formData.append("gradient_color", gradientColor);
    formData.append("primary_image", primaryImage);
    formData.append("secondary_image", secondaryImage);
    formData.append("tertiary_image", tertiaryImage);

    try {
      const res = await axios.patch(
        `${baseUrl}/dashboard/brand-interfaces`,
        formData,
        {
          headers: headersFormData,
        }
      );
      if (res.status === 200) {
        return res.data;
      } else {
        handleOtherStatusCodes(res.status);
        return false;
      }
    } catch (err) {
      handleAxiosError(err);
      return false;
    }
  }

  async get() {
    try {
      const res = await axios.get(`${baseUrl}/dashboard/brand-interfaces`, {
        headers,
      });

      if (res.status === 200) {
        return res.data;
      } else {
        handleOtherStatusCodes(res.status);
        return false;
      }
    } catch (err) {
      handleAxiosError(err);
      return false;
    }
  }
}
