import { useEffect, useState } from "react";
import { Badge, Button, Form, Input, Pagination, Table } from "react-daisyui";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { ActionIndex } from "src/components/action-index";
import { PaginationComponent } from "src/components/pagination";
import { TableComponent } from "src/components/table";
import { ProductsServices } from "src/services/ProductsServices";

export function ProductPage() {
  const navigate = useNavigate();

  const productsServices = new ProductsServices();
  const keyHide = "[HIDDEN_PRODUCT]-";
  const limit = 500;

  const [products, setProducts] = useState({
    data: [],
    meta: {
      total: 0,
    },
  });

  const [tempProducts, setTempProducts] = useState({
    data: [],
    meta: {
      total: 0,
    },
  });

  const [offset, setOffset] = useState(0);

  useEffect(() => {
    fetch();
  }, []);

  async function fetch(pOffset) {
    const res = await productsServices.gets({
      limit: limit,
      offset: pOffset ?? offset,
    });

    if (res?.message != null) {
      toast.error(res.message[0]);
    } else if (res) {
      const temp = res.data.map((item) => {
        return {
          id: item.id,
          name: item.name,
          description: item.description,
          price: item.price,
          originalPrice: item.original_price,
          stock: item.stock,
          displayPic: item.display_pic,
          digitalProduct: item.digital_product != null ? "Digital" : "-"
        };
      });
      setProducts({
        data: temp,
        meta: {
          total: res.meta.total,
        },
      });

      setTempProducts({
        data: temp,
        meta: {
          total: res.meta.total,
        },
      });
    }
  }

  const headerTable = [
    { code: "id", name: "ID" },
    { code: "active", name: "Active", type: "toogle" },
    { code: "displayPic", name: "Picture", type: "image" },
    { code: "name", name: "Name" },
    { code: "description", name: "Description" },
    { code: "originalPrice", name: "Original Price" },
    { code: "price", name: "Price" },
    { code: "stock", name: "Stock" },
    { code: "digitalProduct", name: "Type" },
    { code: "action", name: "Action" },
  ];

  const handleSearch = (text) => {
    const filtered = tempProducts.data.filter((item) =>
      item.name.toLowerCase().includes(text.toLowerCase())
    );

    setProducts({
      data: filtered,
      meta: {
        total: filtered.length,
      },
    });
  };

  async function handleHiddenProduct({ id, name }) {
    let newName = "";
    if (name.includes(keyHide)) {
      newName = name.replace(keyHide, "").trim();
    } else {
      newName = `${keyHide}${name}`;
    }

    const res = await productsServices.hiddenProduct({ name: newName, id: id });

    if (res?.message != null) {
      toast.error(res.message[0]);
    } else if (res) {
      fetch(offset * limit);
      toast.success("Success hide / show product");
    }
  }

  return (
    <div className="col-span-12">
      {/* Search Bar */}
      <ActionIndex
        handleSearch={(text) => handleSearch(text)}
        labelButton="Add Product"
        routeActionButton="/product/add"
      />

      <br />

      {/* Tabel */}
      <div className="overflow-x-auto">
        <Table>
          <Table.Head className="bg-primary-main text-white">
            {headerTable.map((item, index) => {
              return <span key={`title_${index}`}>{item.name}</span>;
            })}
          </Table.Head>
          <Table.Body>
            {products.data.map((item, index) => {
              return (
                <Table.Row key={`row_${index}`}>
                  {headerTable.map((headerItem, headerIndex) => {
                    if (headerItem.code === "action")
                      return (
                        <span
                          className="flex gap-1 text-white"
                          key={`row_${index}_${headerIndex}`}
                        >
                          <Button
                            color="info"
                            className="text-white"
                            size="xs"
                            onClick={() => {
                              navigate("/product/edit/" + item.id);
                            }}
                          >
                            Edit
                          </Button>
                          <Button
                            color="error"
                            className="text-white"
                            size="xs"
                            onClick={async () => {
                              const res = await productsServices.delete({
                                id: item.id,
                              });
                              if (res?.message != null) {
                                toast.error(res.message[0]);
                              } else if (res) {
                                fetch();
                                toast.success("Success delete product");
                              }
                            }}
                          >
                            Delete
                          </Button>
                        </span>
                      );
                    else if (headerItem.type == "boolean")
                      return (
                        <span key={`row_${index}_${headerIndex}`}>
                          {item[headerItem.code] ? "Yes" : "No"}
                        </span>
                      );
                    else if (headerItem.type == "image")
                      return (
                        <span key={`row_${index}_${headerIndex}`}>
                          <img
                            className="h-10"
                            src={item[headerItem.code]}
                            alt={item[headerItem.code]}
                          />
                        </span>
                      );
                    else if (headerItem.type == "toogle")
                      return (
                        <span className="form-control">
                          <input
                            type="checkbox"
                            className="toggle toggle-primary"
                            checked={!item.name.includes(keyHide)}
                            onChange={() => {
                              handleHiddenProduct({
                                id: item.id,
                                name: item.name,
                              });
                            }}
                          />
                        </span>
                      );
                    else
                      return (
                        <span key={`row_${index}_${headerIndex}`}>
                          {item[headerItem.code]}
                        </span>
                      );
                  })}
                </Table.Row>
              );
            })}
          </Table.Body>
        </Table>
      </div>

      <br />

      {/* Pagination */}
      <div className="flex justify-end">
        <PaginationComponent
          active={offset}
          data={Array.from(
            { length: products?.meta?.total / limit + 1 },
            (_, index) => index + 1
          )}
          callback={(index) => {
            fetch(index * limit);
            setOffset(index);
          }}
        />
      </div>
    </div>
  );
}
