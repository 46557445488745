import axios from "axios";
import { baseUrl } from "src/config/Url";
import { headers, headersFormData } from "./config";
import { handleAxiosError, handleOtherStatusCodes } from "./errors";

export class ProductsServices {
  async create({
    name,
    description,
    stock,
    originalPrice,
    price,
    displayPic,
    tenantId,
    menuId,
    submenuId,
    digitalProductId,
    additionalData,
  }) {
    const formData = new FormData();
    formData.append("name", name);
    formData.append("description", description);
    formData.append("stock", stock);
    formData.append("original_price", originalPrice);
    formData.append("price", price);
    formData.append("display_pic", displayPic);
    if (tenantId) formData.append("tenant_id", tenantId);
    formData.append("menu_id", menuId);
    formData.append("submenu_id", submenuId);
    if (digitalProductId != "-") formData.append("digital_product_id", digitalProductId);
    if (additionalData) formData.append("additional_data", additionalData);

    try {
      const res = await axios.post(`${baseUrl}/dashboard/products`, formData, {
        headers: headersFormData,
      });
      if (res.status === 200) {
        return res.data;
      } else {
        handleOtherStatusCodes(res.status);
        return false;
      }
    } catch (err) {
      handleAxiosError(err);
      return false;
    }
  }

  async update({
    name,
    description,
    stock,
    originalPrice,
    price,
    displayPic,
    submenuId,
    digitalProductId,
    additionalData,
    id,
  }) {
    const formData = new FormData();
    formData.append("name", name);
    formData.append("description", description);
    formData.append("stock", stock);
    formData.append("original_price", originalPrice);
    formData.append("price", price);
    if (displayPic) formData.append("display_pic", displayPic);
    formData.append("submenu_id", submenuId);
    if (digitalProductId != "-") formData.append("digital_product_id", digitalProductId);
    if (additionalData) formData.append("additional_data", additionalData);

    try {
      const res = await axios.patch(
        `${baseUrl}/dashboard/products/${id}`,
        formData,
        {
          headers: headersFormData,
        }
      );
      if (res.status === 200) {
        return res.data;
      } else {
        handleOtherStatusCodes(res.status);
        return false;
      }
    } catch (err) {
      handleAxiosError(err);
      return false;
    }
  }

  async editStock({ stock, id }) {
    const formData = new FormData();
    formData.append("stock", stock);

    try {
      const res = await axios.patch(
        `${baseUrl}/dashboard/products/${id}`,
        formData,
        {
          headers: headersFormData,
        }
      );
      if (res.status === 200) {
        return res.data;
      } else {
        handleOtherStatusCodes(res.status);
        return false;
      }
    } catch (err) {
      handleAxiosError(err);
      return false;
    }
  }

  async get({ id }) {
    try {
      const res = await axios.get(`${baseUrl}/dashboard/products/${id}`, {
        headers,
      });

      if (res.status === 200) {
        return res.data;
      } else {
        handleOtherStatusCodes(res.status);
        return false;
      }
    } catch (err) {
      handleAxiosError(err);
      return false;
    }
  }

  async gets({ limit = 20, offset = 0 }) {
    try {
      const res = await axios.get(
        `${baseUrl}/dashboard/products?limit=${limit}&offset=${offset}`,
        { headers }
      );
      if (res.status === 200) {
        return res.data;
      } else {
        handleOtherStatusCodes(res.status);
        return false;
      }
    } catch (err) {
      handleAxiosError(err);
      return false;
    }
  }

  async delete({ id }) {
    try {
      const res = await axios.delete(`${baseUrl}/dashboard/products/${id}`, {
        headers,
      });
      if (res.status === 200) {
        return res.data;
      } else {
        handleOtherStatusCodes(res.status);
        return false;
      }
    } catch (err) {
      handleAxiosError(err);
      return false;
    }
  }

  async hiddenProduct({ name, id }) {
    const formData = new FormData();
    formData.append("name", name);

    try {
      const res = await axios.patch(
        `${baseUrl}/dashboard/products/${id}`,
        formData,
        {
          headers: headersFormData,
        }
      );
      if (res.status === 200) {
        return res.data;
      } else {
        handleOtherStatusCodes(res.status);
        return false;
      }
    } catch (err) {
      handleAxiosError(err);
      return false;
    }
  }
}
