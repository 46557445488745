import { useEffect, useState } from "react";
import { Button, Checkbox } from "react-daisyui";
import toast from "react-hot-toast";
import { useNavigate, useParams } from "react-router-dom";
import { InputDefault } from "src/components/input/input-default";
import { InputImage } from "src/components/input/input-image";
import { InputSelect } from "src/components/input/input-select";
import { InputTextarea } from "src/components/input/input-textarea";
import { DigitalProductServices } from "src/services/DigitalProductServices";
import { ProductsServices } from "src/services/ProductsServices";
import { SubmenusServices } from "src/services/SubmenusServices";

export function ProductEditPage() {
  const navigate = useNavigate();
  const { id } = useParams();

  const productsServices = new ProductsServices();
  const submenuServices = new SubmenusServices();
  const digitalProductServices = new DigitalProductServices();

  const [submenus, setSubmenus] = useState([]);
  const [digitalProducts, setDigitalProducts] = useState([]);
  const [offset, setOffset] = useState(0);

  const [product, setProduct] = useState({
    name: "",
    description: "",
    stock: "",
    originalPrice: "",
    price: "",
    displayPic: "",
    submenuId: "",
    digitalProductId: "-",
    additionalData: "",
  });

  const [preview, setPreview] = useState(null);

  const [subscriptionDay, setSubscriptionDay] = useState(null)
  const [clickAction, setClickAction] = useState(false)


  useEffect(() => {
    fetch();
    fetchDigitalProduct();
  }, []);

  async function fetch(pOffset) {
    const resProduct = await productsServices.get({ id: id });

    if (resProduct?.message != null) {
      toast.error(resProduct.message[0]);
    } else if (resProduct) {

      setProduct({
        name: resProduct.name,
        description: resProduct.description,
        stock: resProduct.stock,
        originalPrice: resProduct.original_price,
        price: resProduct.price,
        displayPic: "",
        submenuId: resProduct.submenu_id,
        digitalProductId: resProduct.digital_product?.id ?? "-",
        additionalData: resProduct.additional_data,
      });

      if(resProduct.digital_product_id != null) setSubscriptionDay(resProduct.additional_data.subscription_time_days)

      setPreview(resProduct.display_pic);
    }
  }

  async function fetchDigitalProduct() {
    const res = await digitalProductServices.gets();

    if (res?.message != null) {
      toast.error(res.message[0]);
    } else if (res) {
      const temp = res.map((item) => {
        return {
          label: item.name,
          value: item.id,
        };
      });

      setDigitalProducts([
        {
          label: "-",
          value: "-",
        },
        ...temp,
      ]);
    }
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    setProduct((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleFileChange = (e) => {
    const { name, files } = e.target;
    setProduct((prevState) => ({
      ...prevState,
      [name]: files[0],
    }));
    setPreview(URL.createObjectURL(files[0]));
  };

  const handleSubmit = async (e) => {
    setClickAction(true)
    e.preventDefault();

    let tempProduct = {}

    if(product.digitalProductId != "-"){
      if(subscriptionDay == null ) {
        toast.error("Subscription day empty!")
        setClickAction(false)
        return
      }
        tempProduct = {
          ...product,
          additionalData: `{"subscription_time_days": `+ subscriptionDay.toString() + `}`
        }
    } else {
      tempProduct = {
        ...product
      }
    }

    const res = await productsServices.update({ ...tempProduct, id });

    if (res?.message != null) {
      toast.error(res.message[0]);
    } else if (res) {
      toast.success("Success edit product");
      navigate("/product");
    }
  };

  return (
    <div className="col-span-12">
      <form onSubmit={handleSubmit}>
        <div className="grid grid-cols-12 gap-6">
          <div className="col-span-12 sm:col-span-6 bg-white shadow-lg py-8 px-6 rounded-lg h-fit">
            <h4 className="f-h4 text-center">Edit Product</h4>
            <br />
            <div className="mt-0">
              <InputDefault
                label="Name"
                name="name"
                value={product.name}
                handleChange={handleChange}
                placeholder=""
                type="text"
                required={true}
              />
            </div>
            <div className="mt-2">
              <InputTextarea
                label="Description"
                name="description"
                value={product.description}
                handleChange={handleChange}
                placeholder=""
                type="text"
                required={true}
                rows={3}
              />
            </div>
            <div className="mt-2">
              <InputDefault
                label="Stock"
                name="stock"
                value={product.stock}
                handleChange={handleChange}
                placeholder=""
                type="number"
                required={true}
              />
            </div>

            <div className="mt-2">
              <InputDefault
                label="Original Price"
                name="originalPrice"
                value={product.originalPrice}
                handleChange={handleChange}
                placeholder=""
                type="number"
                required={true}
              />
            </div>

            <div className="mt-2">
              <InputDefault
                label="Price"
                name="price"
                value={product.price}
                handleChange={handleChange}
                placeholder=""
                type="number"
                required={true}
              />
            </div>
          </div>
          <div className="col-span-12 sm:col-span-6 bg-white shadow-lg py-8 px-6 rounded-lg h-fit">
            <div className="mt-2">
              <InputImage
                label="Image Branch"
                name="displayPic"
                value={product.displayPic}
                preview={preview}
                handleChange={handleFileChange}
              />
            </div>

            <div className="mt-2">
              <InputSelect
                label="Digital Product"
                name="digitalProductId"
                value={product.digitalProductId}
                handleChange={handleChange}
                placeholder=""
                options={
                  digitalProducts || []
                }
              />
            </div>

            <div className="mt-2">
            <InputDefault
                label="Subscription Day"
                name="additionalData"
                value={subscriptionDay}
                handleChange={(e) => {
                  setSubscriptionDay(e.target.value)
                }}
                placeholder=""
                type="number"
              />
            </div>

            <div className="mt-4 flex gap-4">
              <Button
                className="grow"
                color="neutral"
                onClick={() => {
                  navigate("/product");
                }}
                type="button"
              >
                Cancel
              </Button>
              <Button
                disabled={clickAction}
                className="bg-primary-main grow"
                color="neutral"
                type="submit"
              >
                Submit
              </Button>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}
