import toast from "react-hot-toast";

export function JWTPayload(token) {
  try {
    const parts = token.split(".");

    if (parts.length === 3) {
      const payload = base64UrlDecode(parts[1]);
      return payload;
    } else {
      return false;
    }
  } catch (error) {
    // console.log(error);
    document.cookie = "token=; Max-Age=0";
    window.location.href = "/";
    toast.error("Invalid Token");
  }
}

function base64UrlDecode(base64Url) {
  try {
    while (base64Url.length % 4 !== 0) {
      base64Url += "=";
    }

    const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");

    const decoded = atob(base64);

    return JSON.parse(decoded);
  } catch (error) {
    document.cookie = "token=; Max-Age=0";
    window.location.href = "/";
    toast.error("Invalid Token");
    // console.log(error);
  }
}
