import axios from "axios";
import { baseUrl } from "src/config/Url";
import { headers, headersFormData } from "./config";
import { handleAxiosError, handleOtherStatusCodes } from "./errors";

export class OrdersServices {
  async get({ id }) {
    try {
      const res = await axios.get(`${baseUrl}/dashboard/orders/${id}`, {
        headers,
      });

      if (res.status === 200) {
        return res.data;
      } else {
        handleOtherStatusCodes(res.status);
        return false;
      }
    } catch (err) {
      handleAxiosError(err);
      return false;
    }
  }

  async gets({ limit = 20, offset = 0 }) {
    try {
      const res = await axios.get(
        `${baseUrl}/dashboard/orders?limit=${limit}&offset=${offset}`,
        { headers }
      );
      if (res.status === 200) {
        return res.data;
      } else {
        handleOtherStatusCodes(res.status);
        return false;
      }
    } catch (err) {
      handleAxiosError(err);
      return false;
    }
  }
}
