import { useEffect, useState } from "react";
import { Badge, Button, Form, Input, Pagination, Table } from "react-daisyui";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { ActionIndex } from "src/components/action-index";
import { PaginationComponent } from "src/components/pagination";
import { TableComponent } from "src/components/table";
import { MembersServices } from "src/services/MembersServices";

export function MemberPage() {
  const navigate = useNavigate();

  const membersServices = new MembersServices();
  const limit = 20;

  const [members, setMembers] = useState({
    data: [],
    meta: {
      total: 0,
    },
  });

  const [tempMembers, setTempMembers] = useState({
    data: [],
    meta: {
      total: 0,
    },
  });

  const [offset, setOffset] = useState(0);

  useEffect(() => {
    fetch();
  }, []);

  async function fetch(pOffset) {
    const res = await membersServices.gets({
      limit: limit,
      offset: pOffset ?? offset,
    });

    if (res?.message != null) {
      toast.error(res.message[0]);
    } else if (res) {
      const temp = res.data.map((item) => {
        return {
          id: item.id,
          name: item.name,
          email: item.email,
        };
      });
      setMembers({
        data: temp,
        meta: {
          total: res.meta.total,
        },
      });

      setTempMembers({
        data: temp,
        meta: {
          total: res.meta.total,
        },
      });
    }
  }

  const headerTable = [
    { code: "id", name: "ID" },
    { code: "name", name: "Name" },
    { code: "email", name: "Email" },
    { code: "action", name: "Action" },
  ];

  const handleSearch = (text) => {
    const filtered = tempMembers.data.filter((item) =>
      item.name.toLowerCase().includes(text.toLowerCase())
    );

    setMembers({
      data: filtered,
      meta: {
        total: filtered.length,
      },
    });
  };

  return (
    <div className="col-span-12">
      {/* Search Bar */}
      <ActionIndex
        handleSearch={(text) => handleSearch(text)}
        labelButton="Add Member"
        routeActionButton="/member/add"
      />

      <br />

      {/* Tabel */}
      <TableComponent
        header={headerTable}
        data={members.data}
        action={[
          {
            color: "info",
            name: "Detail",
            callback: async (id) => {
              navigate(`/member/${id}`);
            },
          },
          {
            color: "info",
            name: "Edit",
            callback: async (id) => {
              navigate(`/member/edit/${id}`);
            },
          },
          {
            color: "error",
            name: "Delete",
            callback: async (id) => {
              const res = await membersServices.delete({ id });
              if (res?.message != null) {
                toast.error(res.message[0]);
              } else if (res) {
                fetch();
                toast.success("Success delete member");
              }
            },
          },
        ]}
      />

      <br />

      {/* Pagination */}
      <div className="flex justify-end">
        <PaginationComponent
          active={offset}
          data={Array.from(
            { length: members?.meta?.total / limit + 1 },
            (_, index) => index + 1
          )}
          callback={(index) => {
            fetch(index * limit);
            setOffset(index);
          }}
        />
      </div>
    </div>
  );
}
