import axios from "axios";
import { baseUrl } from "src/config/Url";
import { headers, headersDownload, headersFormData } from "./config";
import { handleAxiosError, handleOtherStatusCodes } from "./errors";

export class ReportServices {
  async getOrders({
    limit = 20,
    offset = 0,
    startDate,
    endDate,
    tenantId = null,
  }) {
    try {
      const res = await axios.get(
        `${baseUrl}/dashboard/reports/orders?tenant_id=${tenantId}&limit=${limit}&offset=${offset}&start_date=${startDate}&end_date=${endDate}`,
        { headers }
      );
      if (res.status === 200) {
        return res.data;
      } else {
        handleOtherStatusCodes(res.status);
        return false;
      }
    } catch (err) {
      handleAxiosError(err);
      return false;
    }
  }

  async getOrdersDownload({ startDate, endDate }) {
    try {
      const res = await axios.get(
        `${baseUrl}/dashboard/reports/orders/download_excel?start_date=${startDate}&end_date=${endDate}`,
        { headers: headersDownload }
      );
      if (res.status === 200) {
        return res;
      } else {
        handleOtherStatusCodes(res.status);
        return false;
      }
    } catch (err) {
      handleAxiosError(err);
      return false;
    }
  }
}
