import { useContext, useEffect, useState } from "react";
import { Button, Checkbox } from "react-daisyui";
import toast from "react-hot-toast";
import { useNavigate, useParams } from "react-router-dom";
import { InputCheckbox } from "src/components/input/input-checkbox";
import { InputDefault } from "src/components/input/input-default";
import { InputImage } from "src/components/input/input-image";
import { InputSelect } from "src/components/input/input-select";
import { UserContext } from "src/context/UserContext";
import { MenuServices } from "src/services/MenuServices";
import { TenantsServices } from "src/services/TenantsServices";

export function MainMenuEditPage() {
  const navigate = useNavigate();
  const { id } = useParams();

  const menusServices = new MenuServices();
  const [mainMenu, setMainMenu] = useState({
    name: "",
    displayPic: null,
    isPromotion: false,
    tenantId: "",
  });

  const [preview, setPreview] = useState(null);

  useEffect(() => {
    fetch();
  }, []);

  async function fetch() {
    const res = await menusServices.get({ id: id });
    if (res?.message != null) {
      toast.error(res.message[0]);
    } else if (res) {
      setMainMenu({
        name: res.name,
        displayPic: "",
        isPromotion: res.is_promotion,
      });
      setPreview(res.display_pic);
    }
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    setMainMenu((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleFileChange = (e) => {
    const { name, files } = e.target;
    setMainMenu((prevState) => ({
      ...prevState,
      [name]: files[0],
    }));
    setPreview(URL.createObjectURL(files[0]));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const res = await menusServices.update({ ...mainMenu, id });

    if (res?.message != null) {
      toast.error(res.message[0]);
    } else if (res) {
      toast.success("Success edit menu");
      navigate("/main-menu");
    }
  };

  return (
    <div className="col-span-12">
      <form onSubmit={handleSubmit}>
        <div className="grid grid-cols-12 gap-6">
          <div className="col-span-12 sm:col-span-6 bg-white shadow-lg py-8 px-6 rounded-lg h-fit">
            <h4 className="f-h4 text-center">Edit Main Menu</h4>
            <br />
            <div className="mt-0">
              <InputDefault
                label="Menu Name"
                name="name"
                value={mainMenu.name}
                handleChange={handleChange}
                placeholder=""
                type="text"
                required={true}
              />
            </div>

            <div className="mt-2">
              <InputImage
                label="Image Menu"
                name="displayPic"
                value={mainMenu.displayPic}
                preview={preview}
                handleChange={handleFileChange}
              />
            </div>
            <div className="flex mt-2 items-center gap-2">
              {/* <Checkbox
                onChange={handleChange}
                name="isPromotion"
                value={mainMenu.isPromotion}
              /> */}
              <InputCheckbox
                label="Promotion"
                name="isPromotion"
                value={mainMenu.isPromotion}
                handleChange={(e) => {
                  const { name, checked } = e.target;
                  setMainMenu((prevState) => ({
                    ...prevState,
                    [name]: checked,
                  }));
                }}
              />
              <label className="f-label">Promotion</label>
            </div>

            <div className="mt-4 flex gap-4">
              <Button
                className="grow"
                color="neutral"
                onClick={() => {
                  navigate("/main-menu");
                }}
                type="button"
              >
                Cancel
              </Button>
              <Button
                className="bg-primary-main grow"
                color="neutral"
                type="submit"
              >
                Submit
              </Button>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}
