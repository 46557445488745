import { useEffect, useState } from "react";
import { Button } from "react-daisyui";
import toast from "react-hot-toast";
import { useNavigate, useParams } from "react-router-dom";
import { InputDefault } from "src/components/input/input-default";
import { InputImage } from "src/components/input/input-image";
import { ManagersServices } from "src/services/ManagersServices";

export function BranchEditPage() {
  const navigate = useNavigate();
  const managersService = new ManagersServices();

  const { id } = useParams();

  const [branch, setBranch] = useState({
    name: "",
    email: "",
    password: "",
    phone: "",
    branchName: "",
    branchImage: "",
  });

  const [preview, setPreview] = useState(null);

  useEffect(() => {
    fetch();
  }, []);

  async function fetch() {
    const res = await managersService.get({ id: id });

    if (res?.message != null) {
      toast.error(res.message[0]);
    } else if (res) {
      setBranch({
        name: res.name,
        email: res.email,
        password: "",
        phone: res.phone,
        branchName: res.branch.name,
      });
      setPreview(res.branch.image);
    }
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    setBranch((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleFileChange = (e) => {
    const { name, files } = e.target;
    setBranch((prevState) => ({
      ...prevState,
      [name]: files[0],
    }));
    setPreview(URL.createObjectURL(files[0]));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const res = await managersService.update({ ...branch, id });

    if (res?.message != null) {
      toast.error(res.message[0]);
    } else if (res) {
      toast.success("Success update manager (branch) " + branch.branchName);
      navigate("/manager");
    }
  };

  return (
    <div className="col-span-12">
      <form onSubmit={handleSubmit}>
        <div className="grid grid-cols-12 gap-6">
          <div className="col-span-12 sm:col-span-6 bg-white shadow-lg py-8 px-6 rounded-lg h-fit">
            <h4 className="f-h4 text-center">Edit Branch (Manager)</h4>
            <br />
            <div className="mt-0">
              <InputDefault
                label="Branch"
                name="branchName"
                value={branch.branchName || ""}
                handleChange={handleChange}
                type="text"
                required={true}
              />
            </div>
            <div className="mt-2">
              <InputDefault
                label="Manager"
                name="name"
                value={branch.name || ""}
                handleChange={handleChange}
                type="text"
                required={true}
              />
            </div>
            <div className="mt-2">
              <InputDefault
                label="Email"
                name="email"
                value={branch.email || ""}
                handleChange={handleChange}
                type="email"
                required={true}
              />
            </div>
            <div className="mt-2">
              <InputDefault
                label="Password"
                name="password"
                value={branch.password || ""}
                handleChange={handleChange}
                type="password"
                required={true}
              />
            </div>
            <div className="mt-2">
              <InputDefault
                label="Phone"
                name="phone"
                value={branch.phone || ""}
                handleChange={handleChange}
                type="text"
                required={true}
              />
            </div>
          </div>

          <div className="col-span-12 sm:col-span-6 bg-white shadow-lg py-8 px-6 rounded-lg h-fit">
            <div className="mt-2">
              <InputImage
                label="Image Branch"
                name="branchImage"
                value={branch.branchImage || ""}
                preview={preview}
                handleChange={handleFileChange}
                // required={true}
              />
            </div>

            <div className="mt-4 flex gap-4">
              <Button
                className="grow"
                color="neutral"
                onClick={() => {
                  navigate("/branch");
                }}
                type="button"
              >
                Cancel
              </Button>
              <Button
                className="bg-primary-main grow"
                color="neutral"
                onClick={() => {}}
                type="submit"
              >
                Submit
              </Button>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}
