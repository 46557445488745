import { useEffect, useState } from "react";
import { Badge, Button, Form, Input, Pagination, Table } from "react-daisyui";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { ActionIndex } from "src/components/action-index";
import { PaginationComponent } from "src/components/pagination";
import { TableComponent } from "src/components/table";
import { OrdersServices } from "src/services/OrdersServices";

export function OrderPage() {
  const navigate = useNavigate();

  const orderServices = new OrdersServices();

  const [orders, setOrders] = useState({
    data: [],
    meta: {
      total: 0,
    },
  });

  const [offset, setOffset] = useState(0);

  useEffect(() => {
    fetch();
  }, []);

  async function fetch(pOffset) {
    const res = await orderServices.gets({
      limit: 20,
      offset: pOffset ?? offset,
    });

    if (res?.message != null) {
      toast.error(res.message[0]);
    } else if (res) {
      const temp = res.data.map((item) => {
        return {
          id: item.id,
          customerName: item.customer_name ?? "Guest",
          tableNumber: item.table_number ?? "-",
        };
      });

      setOrders({
        data: temp,
        meta: res.meta,
      });
    }
  }

  const headerTable = [
    { code: "id", name: "ID" },
    { code: "customerName", name: "Customer Name" },
    { code: "tableNumber", name: "Table Number" },
    { code: "action", name: "Action" },
  ];

  return (
    <div className="col-span-12">
      <br />

      {/* Tabel */}
      <TableComponent
        header={headerTable}
        action={[
          {
            color: "info",
            name: "Detail",
            callback: (id) => {
              navigate("/order-report/detail/" + id);
            },
          },
        ]}
        data={orders.data || []}
      />

      <br />

      {/* Pagination */}
      <div className="flex justify-end">
        <PaginationComponent
          active={offset}
          data={Array.from(
            { length: orders?.meta?.total / 10 },
            (_, index) => index + 1
          )}
          callback={(index) => {
            fetch(index);
            setOffset(index);
          }}
        />
      </div>
    </div>
  );
}
