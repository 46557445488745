import { useEffect, useState } from "react";
import { Badge, Button, Form, Input, Pagination, Table } from "react-daisyui";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { ActionIndex } from "src/components/action-index";
import { PaginationComponent } from "src/components/pagination";
import { TableComponent } from "src/components/table";
import { DigitalProductServices } from "src/services/DigitalProductServices";

export function DigitalProductPage() {
  const navigate = useNavigate();

  const digitalProductServices = new DigitalProductServices()

  const [digitalProducts, setDigitalProducts] = useState([]);
  const [tempDigitalProducts, setTempDigitalProducts] = useState([]);

  useEffect(() => {
    fetch();
  }, []);

  async function fetch(pOffset) {
    const res = await digitalProductServices.gets();

    if (res?.message != null) {
      toast.error(res.message[0]);
    } else if (res) {
      const temp = res.map((item) => {
        return {
          id: item.id,
          name: item.name,
          description: item.description,
          digitalType: item.digital_type
        };
      });
      setDigitalProducts(temp);

      setTempDigitalProducts(temp);
    }
  }

  const headerTable = [
    { code: "id", name: "ID" },
    { code: "name", name: "Name" },
    { code: "description", name: "Description" },
    { code: "digitalType", name: "Digital Type" },
    { code: "action", name: "Action" },
  ];

  const handleSearch = (text) => {
    const filtered = tempDigitalProducts.filter((item) =>
      item.name.toLowerCase().includes(text.toLowerCase())
    );

    setDigitalProducts(filtered);
  };

  async function handleDelete(id){
    const res = await digitalProductServices.delete({id: id})

    if (res?.message != null) {
      toast.error(res.message[0]);
    } else if (res) {
      fetch();
      toast.success("Success delete digital product");
    }
  }

  return (
    <div className="col-span-12">
      {/* Search Bar */}
      <ActionIndex
        handleSearch={(text) => handleSearch(text)}
        labelButton="Add Digital Product"
        routeActionButton="/digital-product/add"
      />

      <br />

      {/* Tabel */}
      <TableComponent
        header={headerTable}
        data={digitalProducts}
        action={[
          {
            color: "info",
            name: "Edit",
            callback: async (id) => {
              navigate(`/digital-product/edit/${id}`);
            },
          },
          {
            color: "error",
            name: "Delete",
            callback: async (id) => {
              await handleDelete(id)
              fetch()
            },
          },
        ]}
      />

      <br />

     
    </div>
  );
}
