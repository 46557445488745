const ExcelJS = require("exceljs");

// Function to save data to an Excel file
export async function saveDataToExcel(data, excelFilePath) {
  const workbook = new ExcelJS.Workbook();
  const worksheet = workbook.addWorksheet("Data");

  // Assuming data is an array of objects
  data.forEach((item) => {
    worksheet.addRow(item);
  });

  await workbook.xlsx.writeFile(excelFilePath);
  console.log("Data saved to Excel file:", excelFilePath);
}
