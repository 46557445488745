export function BillPage() {
  return (
    <div className="">
      <div className="bg-white rounded-xl p-6 w-[285px] text-neutral-900">
        <div className="flex items-center">
          <div className="grow">
            <h1 className="f-h1">43</h1>
          </div>
          <div className="text-right">
            {/* <img src="/assets/icon/x.svg" className="w-3 ml-auto mb-4" /> */}
            <p className="f-p2-m">Payment by</p>
            <p className="f-p2-r ">QRIS</p>
          </div>
        </div>
        <div className="mt-2">
          <p className="f-p1-m">Invoice: 08-1234-287</p>
        </div>
        <div className="mt-4 flex">
          <div className="grow">
            <div className="">
              <p className="f-p2-r">Service</p>
              <p className="f-p1-m ">Dine In</p>
            </div>
            <div className="mt-2">
              <p className="f-p2-r">Customer</p>
              <p className="f-p1-m ">Annisa</p>
            </div>
          </div>
          <div className="grow">
            <div className="">
              <p className="f-p2-r">Date</p>
              <p className="f-p1-m ">25/01/23</p>
            </div>
            <div className="mt-2 ">
              <p className="f-p2-r">Order Time</p>
              <p className="f-p1-m ">10.37</p>
            </div>
          </div>
        </div>
        <div className="border border-dashed w-full my-4 border-neutral-600"></div>
        <div className="mt-2">
          <h4 className="f-h4">Order Details</h4>
          <div className="list-group mt-2">
            <div className="grid grid-cols-12">
              <div className="col-span-8">
                <h5 className="f-h5">Sayu Ramen</h5>
              </div>
              <div className="col-span-4">
                <p className="f-p2-r">x1</p>
              </div>
            </div>
            <div className="grid grid-cols-12">
              <div className="col-span-8">
                <p className="f-p2-r">Spicy</p>
              </div>
              <div className="col-span-4">
                <p className="f-p2-r">IDR 22.000</p>
              </div>
            </div>
          </div>
          <div className="list-group mt-2">
            <div className="grid grid-cols-12">
              <div className="col-span-8">
                <h5 className="f-h5">Meatball</h5>
              </div>
              <div className="col-span-4">
                <p className="f-p2-r">x1</p>
              </div>
            </div>
            <div className="grid grid-cols-12">
              <div className="col-span-8">
                <p className="f-p2-r">Normal</p>
              </div>
              <div className="col-span-4">
                <p className="f-p2-r">IDR 22.000</p>
              </div>
            </div>
          </div>
        </div>
        <div className="border border-dashed w-full my-4 border-neutral-600"></div>
        <div className="mt-2">
          <div className="flex justify-between items-center">
            <p className="f-p2-r">Sub Total</p>
            <p className="f-p2-r">IDR 42.000</p>
          </div>
          <div className="flex justify-between items-center">
            <p className="f-p2-r">Discount</p>
            <p className="f-p2-r">IDR 0</p>
          </div>
          <div className="flex justify-between items-center">
            <p className="f-p2-r">Tax</p>
            <p className="f-p2-r">IDR 4.200</p>
          </div>
        </div>
        <div className="border border-dashed w-full my-4 border-neutral-600"></div>
        <div className="mt-2 flex items-center justify-between">
          <h3 className="f-h4">Total</h3>
          <h3 className="f-h4">IDR 46.200</h3>
        </div>
      </div>
    </div>
  );
}
