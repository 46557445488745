import { useState } from "react";
import { Button } from "react-daisyui";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { InputDefault } from "src/components/input/input-default";
import { InputImage } from "src/components/input/input-image";
import { TenantsServices } from "src/services/TenantsServices";

export function TenantAddPage() {
  const navigate = useNavigate();

  const tenantsServices = new TenantsServices();

  // const [tenant, setTenant] = useState({
  //   name: "10 SLAMET",
  //   image: "",
  //   operatorName: "SLAMET",
  //   operatorPassword: "TENANT10unairb_",
  //   operatorPhone: "085733920664",
  //   operatorEmail: "tenant10.unair.b@abada.com",
  // });

  const [tenant, setTenant] = useState({
    name: "",
    image: "",
    operatorName: "",
    operatorPassword: "",
    operatorPhone: "",
    operatorEmail: "",
  });

  const [preview, setPreview] = useState(null);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setTenant((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleFileChange = (e) => {
    const { name, files } = e.target;
    setTenant((prevState) => ({
      ...prevState,
      [name]: files[0],
    }));
    setPreview(URL.createObjectURL(files[0]));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const res = await tenantsServices.create({ ...tenant });

    if (res?.message != null) {
      toast.error(res.message[0]);
    } else if (res) {
      toast.success("Success create tenant");
      navigate("/tenant");
    }
  };

  return (
    <div className="col-span-12">
      <form onSubmit={handleSubmit}>
        <div className="grid grid-cols-12 gap-6">
          <div className="col-span-12 sm:col-span-6 bg-white shadow-lg py-8 px-6 rounded-lg h-fit">
            <h4 className="f-h4 text-center">Add Tenant</h4>
            <br />
            <div className="mt-0">
              <InputDefault
                label="Tenant Name"
                name="name"
                value={tenant.name}
                handleChange={handleChange}
                placeholder=""
                type="text"
                required={true}
              />
            </div>
            <div className="mt-2">
              <InputDefault
                label="Name"
                name="operatorName"
                value={tenant.operatorName}
                handleChange={handleChange}
                type="text"
                required={true}
              />
            </div>
            <div className="mt-2">
              <InputDefault
                label="Email"
                name="operatorEmail"
                placeholder="example@email.com"
                value={tenant.operatorEmail}
                handleChange={handleChange}
                type="email"
                required={true}
              />
            </div>
            <div className="mt-2">
              <InputDefault
                label="Password"
                name="operatorPassword"
                placeholder="********"
                value={tenant.operatorPassword}
                handleChange={handleChange}
                type="password"
                required={true}
              />
            </div>
            <div className="mt-2">
              <InputDefault
                label="Phone"
                name="operatorPhone"
                value={tenant.operatorPhone}
                placeholder="081234567890"
                handleChange={handleChange}
                type="text"
                required={true}
              />
            </div>
          </div>

          <div className="col-span-12 sm:col-span-6 bg-white shadow-lg py-8 px-6 rounded-lg h-fit">
            <div className="mt-2">
              <InputImage
                label="Image Tenant"
                name="image"
                value={tenant.image}
                preview={preview}
                handleChange={handleFileChange}
                required={true}
              />
            </div>

            <div className="mt-4 flex gap-4">
              <Button
                className="grow"
                color="neutral"
                onClick={() => {
                  navigate("/tenant");
                }}
                type="button"
              >
                Cancel
              </Button>
              <Button
                className="bg-primary-main grow"
                color="neutral"
                type="submit"
              >
                Submit
              </Button>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}
