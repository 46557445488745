import { useEffect, useState } from "react";
import { Badge, Button, Form, Input, Pagination, Table } from "react-daisyui";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { ActionIndex } from "src/components/action-index";
import { PaginationComponent } from "src/components/pagination";
import { TableComponent } from "src/components/table";
import { SubmenusServices } from "src/services/SubmenusServices";

export function SubMenuPage() {
  const navigate = useNavigate();
  const submenusServices = new SubmenusServices();
  const limit = 50;

  const [subMenus, setSubMenus] = useState({
    data: [],
    meta: {
      total: 0,
    },
  });

  const [tempSubMenus, setTempSubMenus] = useState({
    data: [],
    meta: {
      total: 0,
    },
  });

  const [offset, setOffset] = useState(0);

  useEffect(() => {
    fetch();
  }, []);

  async function fetch(pOffset) {
    const res = await submenusServices.gets({
      limit: limit,
      offset: pOffset ?? offset,
    });

    if (res?.message != null) {
      toast.error(res.message[0]);
    } else if (res) {
      const temp = res.data.map((item) => {
        return {
          id: item.id,
          name: item.name,
          menuId: item.menu.id,
          menuName: item.menu.name,
          menuPromotion: item.menu.is_promotion,
          tenantId: item.menu.tenant_id,
        };
      });

      setSubMenus({
        data: temp,
        meta: {
          total: res.meta.total,
        },
      });

      setTempSubMenus({
        data: temp,
        meta: {
          total: res.meta.total,
        },
      });
    }
  }

  const headerTable = [
    { code: "id", name: "ID" },
    { code: "name", name: "Name" },
    { code: "menuId", name: "Menu ID" },
    { code: "menuName", name: "Menu Name" },
    { code: "menuPromotion", name: "Menu Promotion", type: "boolean" },
    { code: "tenantId", name: "Tenant ID" },
    { code: "action", name: "Action" },
  ];

  const handleSearch = (text) => {
    const filtered = tempSubMenus.data.filter((item) =>
      item.name.toLowerCase().includes(text.toLowerCase())
    );

    setSubMenus({
      data: filtered,
      meta: {
        total: filtered.length,
      },
    });
  };

  return (
    <div className="col-span-12">
      {/* Search Bar */}
      <ActionIndex
        handleSearch={(text) => handleSearch(text)}
        labelButton="Add Sub Menu"
        routeActionButton="/sub-menu/add"
      />

      <br />

      {/* Tabel */}
      <TableComponent
        header={headerTable}
        action={[
          {
            color: "info",
            name: "Edit",
            callback: (id) => {
              navigate("/sub-menu/edit/" + id);
            },
          },
          {
            color: "error",
            name: "Delete",
            callback: async (id) => {
              const res = await submenusServices.delete({ id });
              if (res?.message != null) {
                toast.error(res.message[0]);
              } else if (res) {
                fetch();
                toast.success("Success delete sub menu");
              }
            },
          },
        ]}
        data={subMenus.data || []}
      />

      <br />

      {/* Pagination */}
      <div className="flex justify-end">
        <PaginationComponent
          active={offset}
          data={Array.from(
            { length: subMenus?.meta?.total / limit + 1 },
            (_, index) => index + 1
          )}
          callback={(index) => {
            fetch(index * limit);
            setOffset(index);
          }}
        />
      </div>
    </div>
  );
}
