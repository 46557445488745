import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { DashboardLayout } from "src/layouts/dahsboard";
import { BranchPage } from "src/pages/branch";
import { BranchAddPage } from "src/pages/branch/add";
import { BranchEditPage } from "src/pages/branch/edit";
import { HomePage } from "src/pages/home";
import { BrandInterfacePage } from "src/pages/brand-interface";
import { BrandInterfaceAddPage } from "src/pages/brand-interface/add";
import { ProfilePage } from "src/pages/profile";
import { ProfileEditPage } from "src/pages/profile/edit";
import { MemberAddPage } from "src/pages/member/add";
import { MemberPage } from "src/pages/member";

export default function OwnerRouterPage() {
  return (
    <BrowserRouter>
      <DashboardLayout>
        <Routes>
          <Route path="/" element={<HomePage />} exact />
          <Route path="/manager" element={<BranchPage />} />
          <Route path="/manager/add" element={<BranchAddPage />} />
          <Route path="/manager/edit/:id" element={<BranchEditPage />} />

          <Route
            path="/brand-interface"
            element={<BrandInterfacePage />}
            exact
          />
          <Route
            path="/brand-interface/add"
            element={<BrandInterfaceAddPage />}
            exact
          />

          <Route path="/member" element={<MemberPage />} />
          <Route path="/member/add" element={<MemberAddPage />} />

          {/* <Route path="/profile/me" element={<ProfilePage />} />
          <Route path="/profile/me/edit" element={<ProfileEditPage />} /> */}

          <Route path="*" element={<HomePage />} exact />
        </Routes>
      </DashboardLayout>
    </BrowserRouter>
  );
}
