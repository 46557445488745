import { useState } from "react";
import { Button } from "react-daisyui";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { InputDefault } from "src/components/input/input-default";
import { InputImage } from "src/components/input/input-image";
import { BrandInterfaceServices } from "src/services/BrandInterfaceServices";

export function BrandInterfaceAddPage() {
  const navigate = useNavigate();

  const brandInterfaceServices = new BrandInterfaceServices();

  const [brandInterface, setBrandInterface] = useState({
    logo: "",
    mainColor: "",
    secondaryColor: "",
    gradientColor: "",
    primaryImage: "",
    secondaryImage: "",
    tertiaryImage: "",
  });

  const [preview, setPreview] = useState({
    logo: null,
    primaryImage: null,
    secondaryImage: null,
    tertiaryImage: null,
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setBrandInterface((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleFileChange = (e) => {
    const { name, files } = e.target;
    setBrandInterface((prevState) => ({
      ...prevState,
      [name]: files[0],
    }));
    setPreview((prevState) => ({
      ...prevState,
      [name]: URL.createObjectURL(files[0]),
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const res = await brandInterfaceServices.create({ ...brandInterface });

    if (res?.message != null) {
      toast.error(res.message[0]);
    } else if (res) {
      toast.success("Success setup interface");
      navigate("/brand-interface");
    }
  };

  return (
    <div className="col-span-12">
      <form onSubmit={handleSubmit}>
        <div className="grid grid-cols-12 gap-6">
          <div className="col-span-12 sm:col-span-6 bg-white shadow-lg py-8 px-6 rounded-lg h-fit">
            <h4 className="f-h4 text-center">Setup Interface</h4>
            <br />
            <div className="mt-0">
              <InputImage
                label="Logo"
                name="logo"
                value={brandInterface.logo}
                preview={preview.logo}
                handleChange={handleFileChange}
                required={true}
              />
            </div>
            <div className="mt-2">
              <InputDefault
                label="Main Color"
                name="mainColor"
                value={brandInterface.mainColor}
                handleChange={handleChange}
                type="text"
                required={true}
              />
            </div>
            <div className="mt-2">
              <InputDefault
                label="Secondary Color"
                name="secondaryColor"
                value={brandInterface.secondaryColor}
                handleChange={handleChange}
                type="text"
                required={true}
              />
            </div>
            <div className="mt-2">
              <InputDefault
                label="Gradient Color"
                name="gradientColor"
                value={brandInterface.gradientColor}
                handleChange={handleChange}
                type="text"
                required={true}
              />
            </div>
          </div>

          <div className="col-span-12 sm:col-span-6 bg-white shadow-lg py-8 px-6 rounded-lg h-fit">
            <div className="mt-2">
              <InputImage
                label="Image Branch 1"
                name="primaryImage"
                value={brandInterface.primaryImage}
                preview={preview.primaryImage}
                handleChange={handleFileChange}
                required={true}
              />
            </div>
            <div className="mt-2">
              <InputImage
                label="Image Branch 2"
                name="secondaryImage"
                value={brandInterface.secondaryImage}
                preview={preview.secondaryImage}
                handleChange={handleFileChange}
                required={true}
              />
            </div>
            <div className="mt-2">
              <InputImage
                label="Image Branch 3"
                name="tertiaryImage"
                value={brandInterface.tertiaryImage}
                preview={preview.tertiaryImage}
                handleChange={handleFileChange}
                required={true}
              />
            </div>

            <div className="mt-4 flex gap-4">
              <Button
                className="grow"
                color="neutral"
                onClick={() => {
                  navigate("/brand-interface");
                }}
                type="button"
              >
                Cancel
              </Button>
              <Button
                className="bg-primary-main grow"
                color="neutral"
                type="submit"
              >
                Submit
              </Button>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}
