import { useEffect, useState } from "react";
import { Button, Checkbox } from "react-daisyui";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { InputDefault } from "src/components/input/input-default";
import { InputSelect } from "src/components/input/input-select";
import { ProfilesServices } from "src/services/ProfilesServices";

export function ProfileEditPage() {
  const navigate = useNavigate();

  const profileServices = new ProfilesServices();

  const [profile, setProfile] = useState({
    name: "",
    email: "",
    phone: "",
  });

  useEffect(() => {
    fetch();
  }, []);

  async function fetch() {
    const res = await profileServices.get();

    if (res?.message != null) {
      toast.error(res.message[0]);
    } else if (res) {
      const temp = {
        name: res.name,
        email: res.email,
        phone: res.phone,
      };
      setProfile(temp);
    }
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    setProfile((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const res = await profileServices.update({ ...profile });

    if (res?.message != null) {
      toast.error(res.message[0]);
    } else if (res) {
      toast.success("Success edit profile");
      navigate("/profile/me");
    }
  };

  return (
    <div className="col-span-12">
      <form onSubmit={handleSubmit}>
        <div className="grid grid-cols-12 gap-6">
          <div className="col-span-12 sm:col-span-6 bg-white shadow-lg py-8 px-6 rounded-lg h-fit">
            <h4 className="f-h4 text-center">Edit Profile</h4>
            <br />
            <div className="mt-0">
              <InputDefault
                label="Name"
                name="name"
                value={profile.name}
                handleChange={handleChange}
                placeholder=""
                type="text"
                required={true}
              />
            </div>
            <div className="mt-0">
              <InputDefault
                label="Email"
                name="email"
                value={profile.email}
                handleChange={handleChange}
                placeholder=""
                type="text"
                required={true}
              />
            </div>
            <div className="mt-0">
              <InputDefault
                label="Phone"
                name="phone"
                value={profile.phone}
                handleChange={handleChange}
                placeholder=""
                type="text"
                required={true}
              />
            </div>

            <div className="mt-4 flex gap-4">
              <Button
                className="grow"
                color="neutral"
                onClick={() => {
                  navigate("/profile/me");
                }}
                type="button"
              >
                Cancel
              </Button>
              <Button
                className="bg-primary-main grow"
                color="neutral"
                type="submit"
              >
                Submit
              </Button>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}
