import { useContext, useEffect, useState } from "react";
import { Button, Checkbox } from "react-daisyui";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { InputDefault } from "src/components/input/input-default";
import { InputSelect } from "src/components/input/input-select";
import { UserContext } from "src/context/UserContext";
import { MenuServices } from "src/services/MenuServices";
import { SubmenusServices } from "src/services/SubmenusServices";
import { TenantsServices } from "src/services/TenantsServices";

export function SubMenuAddPage() {
  const navigate = useNavigate();

  const tenantsServices = new TenantsServices();
  const menuServices = new MenuServices();
  const submenuServices = new SubmenusServices();

  const { user } = useContext(UserContext);

  const [tenants, setTenants] = useState([]);
  const [menus, setMenus] = useState([]);

  const [subMenu, setSubMenu] = useState({
    name: "",
    menuId: "",
    tenantId: "",
  });

  useEffect(() => {
    if (user.userRole == "manager") fetchTenants();
    else fetchMenu();
  }, []);

  async function fetchTenants() {
    const res = await tenantsServices.gets({ offset: 0, limit: 100 });

    if (res?.message != null) {
      toast.error(res.message[0]);
    } else if (res) {
      const temp = res.data.map((item) => {
        return {
          label: item.name,
          value: item.id,
        };
      });

      setSubMenu((prevState) => ({
        ...prevState,
        tenantId: temp[0].value,
      }));
      setTenants(temp);
      await fetchTenantMenus({ tenantId: temp[0].value });
    }
  }

  async function fetchTenantMenus({tenantId}){
    const res = await tenantsServices.getMenus({ tenantId });

    if (res?.message != null) {
      toast.error(res.message[0]);
    } else if (res) {
      const temp = res.data.map((item) => {
        return {
          label: item.name,
          value: item.id,
        };
      });

      setSubMenu((prevState) => ({
        ...prevState,
        menuId: temp[0].value,
      }));
      setMenus(temp);
    }
  }

  async function fetchMenu() {
    const res = await menuServices.gets({
      limit: 100,
      offset: 0,
    });

    if (res?.message != null) {
      toast.error(res.message[0]);
    } else if (res) {
      const temp = res.data.map((item) => {
        return {
          label: item.name,
          value: item.id,
        };
      });

      setSubMenu((prevState) => ({
        ...prevState,
        menuId: temp[0].value,
      }));
      setMenus(temp);
    }
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    setSubMenu((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const res = await submenuServices.create({ ...subMenu });

    if (res?.message != null) {
      toast.error(res.message[0]);
    } else if (res) {
      toast.success("Success create sub menu");
      navigate("/sub-menu");
    }
  };

  return (
    <div className="col-span-12">
      <form onSubmit={handleSubmit}>
        <div className="grid grid-cols-12 gap-6">
          <div className="col-span-12 sm:col-span-6 bg-white shadow-lg py-8 px-6 rounded-lg h-fit">
            <h4 className="f-h4 text-center">Add Sub Menu</h4>
            <br />
            <div className="mt-0">
              <InputDefault
                label="Name"
                name="name"
                value={subMenu.name}
                handleChange={handleChange}
                placeholder=""
                type="text"
                required={true}
              />
            </div>
            {user.userRole == "manager" ? (
              <div className="mt-2">
                <InputSelect
                  label="Tenant"
                  name="tenantId"
                  value={subMenu.tenantId}
                  handleChange={(e) => {
                    handleChange(e);
                    fetchTenantMenus({ tenantId: e.target.value });
                  }}
                  options={tenants}
                  required={true}
                />
              </div>
            ) : null}

            <div className="mt-2">
              <InputSelect
                label="Main Menu"
                name="menuId"
                value={subMenu.menuId}
                handleChange={handleChange}
                placeholder=""
                type="text"
                required={true}
                options={menus || []}
              />
            </div>

            <div className="mt-4 flex gap-4">
              <Button
                className="grow"
                color="neutral"
                onClick={() => {
                  navigate("/sub-menu");
                }}
                type="button"
              >
                Cancel
              </Button>
              <Button
                className="bg-primary-main grow"
                color="neutral"
                type="submit"
              >
                Submit
              </Button>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}
