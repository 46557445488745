import axios from "axios";
import { baseUrl } from "src/config/Url";
import { headers, headersFormData } from "./config";
import { handleAxiosError, handleOtherStatusCodes } from "./errors";

export class OperationalCostServices {
  async create({ name, costDate, taxAmount, amount, tenantId }) {
    try {
      const res = await axios.post(
        `${baseUrl}/dashboard/operational-costs`,
        {
          name: name,
          cost_date: costDate,
          tax_amount: taxAmount,
          amount: amount,
          tenant_id: tenantId,
        },
        {
          headers,
        }
      );
      if (res.status === 200) {
        return res.data;
      } else {
        handleOtherStatusCodes(res.status);
        return false;
      }
    } catch (err) {
      handleAxiosError(err);
      return false;
    }
  }

  async update({ name, costDate, taxtAmount, amount, id }) {
    try {
      const res = await axios.patch(
        `${baseUrl}/dashboard/operational-costs/${id}`,
        {
          name: name,
          cost_date: costDate,
          tax_amount: parseInt(taxtAmount),
          amount: parseInt(amount),
        },
        {
          headers,
        }
      );
      if (res.status === 200) {
        return res.data;
      } else {
        handleOtherStatusCodes(res.status);
        return false;
      }
    } catch (err) {
      handleAxiosError(err);
      return false;
    }
  }

  async get({ id }) {
    try {
      const res = await axios.get(
        `${baseUrl}/dashboard/operational-costs/${id}`,
        {
          headers,
        }
      );

      if (res.status === 200) {
        return res.data;
      } else {
        handleOtherStatusCodes(res.status);
        return false;
      }
    } catch (err) {
      handleAxiosError(err);
      return false;
    }
  }

  async gets({ limit = 20, offset = 0 }) {
    try {
      const res = await axios.get(
        `${baseUrl}/dashboard/operational-costs?limit=${limit}&offset=${offset}`,
        { headers }
      );
      if (res.status === 200) {
        return res.data;
      } else {
        handleOtherStatusCodes(res.status);
        return false;
      }
    } catch (err) {
      handleAxiosError(err);
      return false;
    }
  }

  async delete({ id }) {
    try {
      const res = await axios.delete(
        `${baseUrl}/dashboard/operational-costs/${id}`,
        {
          headers,
        }
      );
      if (res.status === 200) {
        return res.data;
      } else {
        handleOtherStatusCodes(res.status);
        return false;
      }
    } catch (err) {
      handleAxiosError(err);
      return false;
    }
  }
}
