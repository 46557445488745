import { useEffect, useState } from "react";
import { Button } from "react-daisyui";
import toast from "react-hot-toast";
import { useNavigate, useParams } from "react-router-dom";
import { InputDefault } from "src/components/input/input-default";
import { DigitalProductServices } from "src/services/DigitalProductServices";

export function DigitalProductEditPage() {
  const navigate = useNavigate();
  const {id} = useParams();

  const digitalProductServices = new DigitalProductServices();

  const [digitalProduct, setDigitalProduct] = useState({
    name: "",
    description: "",
    digitalType: "",
  });

  useEffect(() => {
    fetch();
  }, []);

  async function fetch() {
    const res = await digitalProductServices.get({id});

    if (res?.message != null) {
      toast.error(res.message[0]);
    } else if (res) {
      setDigitalProduct({
        name: res.name,
        description: res.description,
        digitalType: res.digital_type,
      });
    }
  }


  const handleChange = (e) => {
    const { name, value } = e.target;
    setDigitalProduct((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const res = await digitalProductServices.update({...digitalProduct, id});

    if (res?.message != null) {
      toast.error(res.message[0]);
    } else if (res) {
      toast.success("Success update member");
      navigate("/digital-product");
    }
  };

  return (
    <div className="col-span-12">
      <form onSubmit={handleSubmit}>
        <div className="grid grid-cols-12 gap-6">
          <div className="col-span-12 sm:col-span-6 bg-white shadow-lg py-8 px-6 rounded-lg h-fit">
            <h4 className="f-h4 text-center">Edit Digital Product</h4>
            <br />
            <div className="mt-0">
              <InputDefault
                label="Name"
                name="name"
                value={digitalProduct.name}
                handleChange={handleChange}
                type="text"
                required={true}
                placeholder="Gym Membership"
              />
            </div>
            <div className="mt-2">
              <InputDefault
                label="Description"
                name="description"
                value={digitalProduct.description}
                handleChange={handleChange}
                type="text"
                required={true}
                placeholder="Gym Membership"
              />
            </div>
            {/* <div className="mt-2">
              <InputDefault
                label="Digital Type"
                name="digitalType"
                value={digitalProduct.digitalType}
                type="text"
                required={true}
                placeholder="subscription"
                readOnly={true} 
              />
            </div> */}

            <div className="mt-4 flex gap-4">
              <Button
                className="grow"
                color="neutral"
                onClick={() => {
                  navigate("/digital-product");
                }}
                type="button"
              >
                Cancel
              </Button>
              <Button
                className="bg-primary-main grow"
                color="neutral"
                type="submit"
              >
                Submit
              </Button>
            </div>
            
          </div>

        </div>
      </form>
    </div>
  );
}
