import { useEffect, useState } from "react";
import { Button } from "react-daisyui";
import toast from "react-hot-toast";
import { useNavigate, useParams } from "react-router-dom";
import { InputDefault } from "src/components/input/input-default";
import { MembersServices } from "src/services/MembersServices";

export function MemberDetailPage() {
  const navigate = useNavigate();
  const { id } = useParams();
  const memberServices = new MembersServices();

  const [member, setMember] = useState({
    member_cards: [],
  });
  const [newCard, setNewCard] = useState({
    cardNumber: "",
  });

  useEffect(() => {
    fetchMember();
  }, []);

  async function fetchMember() {
    const res = await memberServices.get({ id });

    setMember(res);
  }

  async function handleSubmit(e) {
    e.preventDefault();

    const res = await memberServices.addMemberCard({
      cardNumber: newCard.cardNumber,
      id,
    });

    if (res?.message != null) {
      toast.error(res.message[0]);
    } else if (res) {
      toast.success("Success add new card");
      fetchMember();
      setNewCard({
        cardNumber: "",
      });
    }
  }

  return (
    <div className="col-span-12">
      <div className="grid grid-cols-12 gap-6">
        <div className="col-span-12 sm:col-span-6">
          <div className=" bg-white shadow-lg py-8 px-6 rounded-lg h-fit">
            <h4 className="f-h4 text-center">Detail Member</h4>

            <br />

            <div className="flex flex-col gap-2">
              <div className="grid grid-cols-3">
                <div className="col-span-1">
                  <h5 className="f-h5">ID</h5>
                </div>
                <div className="col-span-2">
                  <h5 className="f-p1-r">{member.id}</h5>
                </div>
              </div>
              <div className="grid grid-cols-3">
                <div className="col-span-1">
                  <h5 className="f-h5">Name</h5>
                </div>
                <div className="col-span-2">
                  <h5 className="f-p1-r">{member.name}</h5>
                </div>
              </div>
              <div className="grid grid-cols-3">
                <div className="col-span-1">
                  <h5 className="f-h5">Email</h5>
                </div>
                <div className="col-span-2">
                  <h5 className="f-p1-r">{member.email}</h5>
                </div>
              </div>
            </div>
          </div>

          <form
            onSubmit={handleSubmit}
            className="bg-white shadow-lg py-8 px-6 rounded-lg h-fit mt-4"
          >
            <h4 className="f-h4 text-center">Add New Card</h4>

            <br />

            <div className="mt-0">
              <InputDefault
                label="Card Number"
                name="cardNumber"
                value={newCard.cardNumber}
                handleChange={(e) => {
                  setNewCard({ ...newCard, cardNumber: e.target.value });
                }}
                type="text"
                required={true}
                placeholder="1234 5678 9012 3456"
              />
            </div>

            <div className="mt-4 flex gap-4">
              <Button
                className="grow"
                color="neutral"
                onClick={() => {
                  navigate("/member");
                }}
                type="button"
              >
                Cancel
              </Button>
              <Button
                className="bg-primary-main grow"
                color="neutral"
                type="submit"
              >
                Submit
              </Button>
            </div>
          </form>
        </div>

        <div className="col-span-12 sm:col-span-6">
          {member &&
            member.member_cards.map((item, index) => {
              return (
                <div className="bg-white shadow-lg py-8 px-6 rounded-lg h-fit mb-4">
                  <div className="flex flex-col gap-2">
                    <div className="grid grid-cols-3">
                      <div className="col-span-1">
                        <h5 className="f-h5">Card Number</h5>
                      </div>
                      <div className="col-span-2">
                        <h5 className="f-p1-r">{item.card_number}</h5>
                      </div>
                    </div>
                    <div className="grid grid-cols-3">
                      <div className="col-span-1">
                        <h5 className="f-h5">Point</h5>
                      </div>
                      <div className="col-span-2">
                        <h5 className="f-p1-r">{item.point}</h5>
                      </div>
                    </div>
                    <div className="grid grid-cols-3">
                      <div className="col-span-1">
                        <h5 className="f-h5">Expired</h5>
                      </div>
                      <div className="col-span-2">
                        <h5 className="f-p1-r">
                          {new Date(item.expired_at).toLocaleDateString() ||
                            "-"}{" "}
                          {new Date(item.expired_at).toLocaleTimeString() ||
                            "-"}
                        </h5>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
}
