import axios from "axios";
import { baseUrl } from "src/config/Url";
import { headers, headersFormData } from "./config";
import { handleAxiosError, handleOtherStatusCodes } from "./errors";

export class AdvertisesServices {
  async create({ name, asset, startDate, endDate, advertiseType }) {
    const formData = new FormData();
    formData.append("name", name);
    formData.append("asset", asset);
    formData.append("start_date", startDate);
    formData.append("end_date", endDate);
    formData.append("advertise_type", advertiseType);

    try {
      const res = await axios.post(
        `${baseUrl}/dashboard/advertises`,
        formData,
        {
          headers: headersFormData,
        }
      );
      if (res.status === 200) {
        return res.data;
      } else {
        handleOtherStatusCodes(res.status);
        return false;
      }
    } catch (err) {
      handleAxiosError(err);
      return false;
    }
  }

  async update({ name, asset, startDate, endDate, advertiseType, id }) {
    const formData = new FormData();
    formData.append("name", name);

    if (asset !== "") formData.append("asset", asset);

    formData.append("start_date", startDate);
    formData.append("end_date", endDate);
    formData.append("advertise_type", advertiseType);

    try {
      const res = await axios.patch(
        `${baseUrl}/dashboard/advertises/${id}`,
        formData,
        {
          headers: headersFormData,
        }
      );
      if (res.status === 200) {
        return res.data;
      } else {
        handleOtherStatusCodes(res.status);
        return false;
      }
    } catch (err) {
      handleAxiosError(err);
      return false;
    }
  }

  async get({ id }) {
    try {
      const res = await axios.get(`${baseUrl}/dashboard/advertises/${id}`, {
        headers,
      });

      if (res.status === 200) {
        return res.data;
      } else {
        handleOtherStatusCodes(res.status);
        return false;
      }
    } catch (err) {
      handleAxiosError(err);
      return false;
    }
  }

  async gets({ limit = 20, offset = 0 }) {
    try {
      const res = await axios.get(
        `${baseUrl}/dashboard/advertises?limit=${limit}&offset=${offset}`,
        { headers }
      );
      if (res.status === 200) {
        return res.data;
      } else {
        handleOtherStatusCodes(res.status);
        return false;
      }
    } catch (err) {
      handleAxiosError(err);
      return false;
    }
  }

  async delete({ id }) {
    try {
      const res = await axios.delete(`${baseUrl}/dashboard/advertises/${id}`, {
        headers,
      });
      if (res.status === 200) {
        return res.data;
      } else {
        handleOtherStatusCodes(res.status);
        return false;
      }
    } catch (err) {
      handleAxiosError(err);
      return false;
    }
  }
}
