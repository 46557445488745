import { useEffect, useState } from "react";
import { Badge, Button, Form, Input, Pagination, Table } from "react-daisyui";
import toast from "react-hot-toast";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import { ActionIndex } from "src/components/action-index";
import { PaginationComponent } from "src/components/pagination";
import { TableComponent } from "src/components/table";
import { ManagersServices } from "src/services/ManagersServices";

export function BranchPage() {
  const navigate = useNavigate();
  const managersServices = new ManagersServices();
  const limit = 20;

  const [branchs, setBranchs] = useState({
    data: [],
    meta: {
      total: 0,
    },
  });

  const [tempBranchs, setTempBranchs] = useState({
    data: [],
    meta: {
      total: 0,
    },
  });

  const [offset, setOffset] = useState(0);

  useEffect(() => {
    fetch();
  }, []);

  async function fetch(pOffset) {
    const res = await managersServices.gets({
      limit: limit,
      offset: pOffset ?? offset,
    });

    if (res?.message != null) {
      toast.error(res.message[0]);
    } else if (res) {
      const temp = res.data.map((item) => {
        return {
          id: item.id,
          brand_id: item.brand_id,
          branch_id: item.branch_id,
          name: item.name,
          email: item.email,
          phone: item.phone,
        };
      });
      setBranchs({
        data: temp,
        meta: {
          total: res.meta.total,
        },
      });

      setTempBranchs({
        data: temp,
        meta: {
          total: res.meta.total,
        },
      });
    }
  }

  const headerTable = [
    { code: "id", name: "ID" },
    { code: "brand_id", name: "Brand ID" },
    { code: "branch_id", name: "Branch ID" },
    { code: "name", name: "Name" },
    { code: "email", name: "Email" },
    { code: "phone", name: "Phone" },
    { code: "action", name: "Action" },
  ];

  const handleSearch = (text) => {
    const filtered = tempBranchs.data.filter((item) =>
      item.name.toLowerCase().includes(text.toLowerCase())
    );

    setBranchs({
      data: filtered,
      meta: {
        total: filtered.length,
      },
    });
  };

  return (
    <div className="col-span-12">
      {/* Search Bar */}
      <ActionIndex
        handleSearch={(text) => handleSearch(text)}
        labelButton="Add Manager"
        routeActionButton="/manager/add"
      />

      <br />

      {/* Tabel */}
      <TableComponent
        header={headerTable}
        action={[
          {
            color: "info",
            name: "Edit",
            callback: (id) => {
              navigate("/manager/edit/" + id);
            },
          },
          {
            color: "error",
            name: "Delete",
            callback: async (id) => {
              const res = await managersServices.delete({ id });
              if (res?.message != null) {
                toast.error(res.message[0]);
              } else if (res) {
                fetch();
                toast.success("Success delete manager (branch)");
              }
            },
          },
        ]}
        data={branchs.data || []}
      />

      <br />

      {/* Pagination */}
      <div className="flex justify-end">
        <PaginationComponent
          active={offset}
          data={Array.from(
            { length: branchs?.meta?.total / limit + 1 },
            (_, index) => index + 1
          )}
          callback={(index) => {
            fetch(index * limit);
            setOffset(index);
          }}
        />
      </div>
    </div>
  );
}
