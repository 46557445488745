import { useEffect, useState } from "react";
import { Button } from "react-daisyui";
import toast from "react-hot-toast";
import { useNavigate, useParams } from "react-router-dom";
import { InputDefault } from "src/components/input/input-default";
import { InputImage } from "src/components/input/input-image";
import { MembersServices } from "src/services/MembersServices";

export function MemberEditPage() {
  const navigate = useNavigate();
  const {id} = useParams()

  const membersServices = new MembersServices();

  const [member, setMember] = useState({
    name: "",
    photo: null,
    national_id_number: "",
  });

  const [preview, setPreview] = useState(null);

  useEffect(() => {
    fetchMember();
  }, [])

  async function fetchMember() {
    const res = await membersServices.get({ id });
    console.log(res)

    setMember(res);
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    setMember((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleFileChange = (e) => {
    const { name, files } = e.target;
    setMember((prevState) => ({
      ...prevState,
      [name]: files[0],
    }));
    setPreview(URL.createObjectURL(files[0]));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const res = await membersServices.update({ ...member });

    if (res?.message != null) {
      toast.error(res.message[0]);
    } else if (res) {
      toast.success("Success add member");
      navigate("/member");
    }
  };

  return (
    <div className="col-span-12">
      <form onSubmit={handleSubmit}>
        <div className="grid grid-cols-12 gap-6">
          <div className="col-span-12 sm:col-span-6 bg-white shadow-lg py-8 px-6 rounded-lg h-fit">
            <h4 className="f-h4 text-center">Edit Member</h4>
            <br />
            <div className="mt-0">
              <InputDefault
                label="Fullname"
                name="name"
                value={member.name}
                handleChange={handleChange}
                type="text"
                required={true}
                placeholder="John Doe"
              />
            </div>
            
            <div className="mt-2">
              <InputDefault
                label="National ID Number"
                name="national_id_number"
                value={member.national_id_number}
                handleChange={handleChange}
                type="text"
              />
            </div>
          </div>

          <div className="col-span-12 sm:col-span-6 bg-white shadow-lg py-8 px-6 rounded-lg h-fit">
            <div className="mt-2">
              <InputImage
                label="Photo"
                name="photo"
                value={member.photo}
                preview={preview}
                handleChange={handleFileChange}
              />
            </div>

            <div className="mt-4 flex gap-4">
              <Button
                className="grow"
                color="neutral"
                onClick={() => {
                  navigate("/member");
                }}
                type="button"
              >
                Cancel
              </Button>
              <Button
                className="bg-primary-main grow"
                color="neutral"
                type="submit"
              >
                Submit
              </Button>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}
