import { useContext, useEffect, useState } from "react";
import {
  Badge,
  Button,
  Form,
  Input,
  Pagination,
  Select,
  Table,
} from "react-daisyui";
import toast from "react-hot-toast";
import { FiDollarSign } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import { PaginationComponent } from "src/components/pagination";
import { StatsDefaultComponent } from "src/components/stats/stats-default";
import { TableComponent } from "src/components/table";
import { UserContext } from "src/context/UserContext";
import { parseXMLToJSON } from "src/methods/ParseXMLToJSON";
import { saveDataToExcel } from "src/methods/SaveDataToExcel";
import { ReportServices } from "src/services/ReportServices";
import { saveAs } from "file-saver";
import { TenantsServices } from "src/services/TenantsServices";
import { headers } from "src/services/config";
import { baseUrl } from "src/config/Url";

export function TransactionPage() {
  const navigate = useNavigate();

  const reportServices = new ReportServices();

  const tenantsServices = new TenantsServices();

  const { user } = useContext(UserContext);

  const [orders, setOrders] = useState({
    data: [],
    meta: {
      total: 0,
    },
  });

  const [tenants, setTenants] = useState([]);
  const [tenantId, setTenantId] = useState();

  const [offset, setOffset] = useState(0);
  const [rangeDate, setRangeDate] = useState({
    startDate: new Date().toISOString().split("T")[0],
    endDate: new Date().toISOString().split("T")[0],
  });

  useEffect(() => {
    if (user.userRole == "tenant") {
      customFetch();
    } else {
      fetchWithTenantId();
    }
  }, []);

  async function fetchWithTenantId(pOffset) {
    const resTenants = await tenantsServices.gets({
      limit: 100,
      offset: pOffset ?? offset,
    });

    if (resTenants?.message != null) {
      toast.error(resTenants.message[0]);
    } else if (resTenants) {
      const temp = resTenants.data.map((item) => {
        return {
          value: item.id,
          label: item.name,
        };
      });
      setTenants(temp);

      setTenantId(temp[0].value);

      const res = await reportServices.getOrders({
        limit: 20,
        offset: pOffset ?? offset,
        startDate: rangeDate.startDate,
        endDate: rangeDate.endDate,
        tenantId: tenantId || temp[0].value,
      });

      if (res?.message != null) {
        toast.error(res.message[0]);
      } else if (res) {
        const temp = res.data.map((item) => {
          return {
            id: item.order_customer_id,
            customerName: item.customer_name || "Guest",
            orderTime: item.order_time,
            orderDate: item.order_date,
            tableNumber: item.table_number,
            totalAmount: item.total_amount,
            transactionMethod: item.transaction_method,
          };
        });
        setOrders({
          data: temp,
          meta: {
            total: res.meta.total,
          },
        });
      }
    }
  }

  async function customFetch(pOffset) {
    const res = await reportServices.getOrders({
      limit: 20,
      offset: pOffset ?? offset,
      startDate: rangeDate.startDate,
      endDate: rangeDate.endDate,
    });

    if (res?.message != null) {
      toast.error(res.message[0]);
    } else if (res) {
      const temp = res.data.map((item) => {
        return {
          id: item.order_customer_id,
          customerName: item.customer_name || "Guest",
          orderTime: item.order_time,
          orderDate: item.order_date,
          tableNumber: item.table_number,
          totalAmount: item.total_amount,
          transactionMethod: item.transaction_method,
        };
      });
      setOrders({
        data: temp,
        meta: {
          total: res.meta.total,
        },
      });
    }
  }

  const headerTable = [
    { code: "id", name: "ID" },
    { code: "customerName", name: "Customer Name" },
    { code: "transactionMethod", name: "Transaction Method" },
    { code: "orderTime", name: "Order Time" },
    { code: "orderDate", name: "Order Date" },
    { code: "tableNumber", name: "Table Number" },
    { code: "totalAmount", name: "Total Amount" },
  ];

  const handleSearch = (e) => {
    e.preventDefault();
    console.log(e);
  };

  const handleDownloadExcel = async () => {
    const apiEndpoint = `${baseUrl}/dashboard/reports/orders/download_excel?tenant_id=${
      tenantId || null
    }&start_date=${rangeDate.startDate}&end_date=${rangeDate.endDate}`;
    fetch(apiEndpoint, {
      method: "GET",
      headers: { ...headers },
    })
      .then((response) => {
        const contentDisposition = response.headers.get("Content-Disposition");
        let filename = "downloaded-file.xlsx"; // Default filename

        if (contentDisposition) {
          const matches = /filename\*?=["']?(.*?)(?:["']?;|$)/g.exec(
            contentDisposition
          );

          if (matches && matches[1]) {
            filename = decodeURI(matches[1]);
          }
        }

        // Create a Blob with the response data
        return response
          .blob()
          .then((blob) => ({ blob, filename, type: blob.type }));
      })
      .then(({ blob, filename, type }) => {
        const url = window.URL.createObjectURL(new Blob([blob], { type }));
        const a = document.createElement("a");
        a.href = url;
        a.download = filename; // Use the extracted filename
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
      })
      .catch((error) => {
        toast.error(error);
      });
  };

  return (
    <div className="col-span-12">
      {/* <div className="lg:grid-cols-4 md:grid-cols-2 grid-cols-1 gap-4 hidden">
        <StatsDefaultComponent
          icon={<FiDollarSign size={24} />}
          title="Sales"
          value="Rp 1.000.000"
          size="text-xl"
        />
        <StatsDefaultComponent
          icon={<FiDollarSign size={24} />}
          title="HPP"
          value="Rp 1.000.000"
          size="text-xl"
        />
        <StatsDefaultComponent
          icon={<FiDollarSign size={24} />}
          title="Opeational"
          value="Rp 1.000.000"
          size="text-xl"
        />
        <StatsDefaultComponent
          icon={<FiDollarSign size={24} />}
          title="Nett Profit"
          value="Rp 1.000.000"
          size="text-xl"
        />
      </div> */}
      {/* <br /> */}
      {/* Search Bar */}
      <div className="flex gap-4 flex-col md:flex-row">
        <div className="flex gap-2">
          {user.userRole == "manager" ? (
            <Select
              value={tenantId}
              onChange={(e) => {
                setTenantId(e.target.value);
              }}
              className="w-24 md:w-auto bg-base-100 rounded-lg"
            >
              {" "}
              {tenants.map((item) => {
                return (
                  <Select.Option value={item.value}>{item.label}</Select.Option>
                );
              })}
            </Select>
          ) : null}
          <Input
            bordered
            type="date"
            name="startDate"
            onChange={(e) => {
              setRangeDate({ ...rangeDate, [e.target.name]: e.target.value });
            }}
            value={rangeDate.startDate}
            className="w-full md:w-auto bg-base-100 rounded-lg"
          />
          <Input
            bordered
            type="date"
            name="endDate"
            onChange={(e) => {
              setRangeDate({ ...rangeDate, [e.target.name]: e.target.value });
            }}
            value={rangeDate.endDate}
            className="w-full md:w-auto bg-base-100 rounded-lg"
          />
          <Button
            color="neutral"
            onClick={() => {
              if (user.userRole == "tenant") {
                customFetch();
              } else {
                fetchWithTenantId();
              }
            }}
          >
            Apply
          </Button>
        </div>
        <form className="justify-end gap-2 grow hidden" onSubmit={handleSearch}>
          <Input
            bordered
            type="text"
            placeholder="Search"
            className="w-full md:w-auto bg-base-100 rounded-lg"
          />
          <Button color="ghost">Search</Button>
        </form>
      </div>

      <br />

      {/* Tabel */}
      <TableComponent header={headerTable} data={orders.data} />

      <br />

      <Button color="primary" size="sm" onClick={() => handleDownloadExcel()}>
        Download Excel
      </Button>

      {/* Pagination */}
      <div className="flex justify-end">
        <PaginationComponent
          active={offset}
          data={Array.from(
            { length: orders?.meta?.total / 20 + 1 },
            (_, index) => index + 1
          )}
          callback={(index) => {
            fetch(index * 20);
            setOffset(index);
          }}
        />
      </div>
    </div>
  );
}
