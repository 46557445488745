import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { DashboardLayout } from "src/layouts/dahsboard";
import { AdvertisePage } from "src/pages/advertise";
import { AdvertiseAddPage } from "src/pages/advertise/add";
import { AdvertiseEditPage } from "src/pages/advertise/edit";
import { DigitalProductPage } from "src/pages/digital-product";
import { DigitalProductAddPage } from "src/pages/digital-product/add";
import { DigitalProductEditPage } from "src/pages/digital-product/edit";
import { HomePage } from "src/pages/home";
import { MainMenuPage } from "src/pages/main-menu";
import { MainMenuAddPage } from "src/pages/main-menu/add";
import { MainMenuEditPage } from "src/pages/main-menu/edit";
import { MemberPage } from "src/pages/member";
import { MemberAddPage } from "src/pages/member/add";
import { MemberDetailPage } from "src/pages/member/detail";
import { MemberEditPage } from "src/pages/member/edit";
import { OperationalPage } from "src/pages/operational";
import { OperationalAddPage } from "src/pages/operational/add";
import { OperationalEditPage } from "src/pages/operational/edit";
import { ProductPage } from "src/pages/product";
import { ProductAddPage } from "src/pages/product/add";
import { ProductEditPage } from "src/pages/product/edit";
import { ProfilePage } from "src/pages/profile";
import { ProfileEditPage } from "src/pages/profile/edit";
import { SubMenuPage } from "src/pages/sub-menu";
import { SubMenuAddPage } from "src/pages/sub-menu/add";
import { SubMenuEditPage } from "src/pages/sub-menu/edit";
import { TaxPage } from "src/pages/tax";
import { TaxAddPage } from "src/pages/tax/add";
import { TenantPage } from "src/pages/tenant";
import { TenantAddPage } from "src/pages/tenant/add";
import { TenantEditPage } from "src/pages/tenant/edit";
import { TransactionPage } from "src/pages/transaction";

export default function ManagerRouterPage() {
  return (
    <BrowserRouter>
      <DashboardLayout>
        <Routes>
          <Route path="/" element={<HomePage />} />

          <Route path="/tax" element={<TaxPage />} />
          <Route path="/tax/add" element={<TaxAddPage />} />

          <Route path="/tenant" element={<TenantPage />} />
          <Route path="/tenant/add" element={<TenantAddPage />} />
          <Route path="/tenant/edit/:id" element={<TenantEditPage />} />

          <Route path="/advertise" element={<AdvertisePage />} />
          <Route path="/advertise/add" element={<AdvertiseAddPage />} />
          <Route path="/advertise/edit/:id" element={<AdvertiseEditPage />} />

          <Route path="/member" element={<MemberPage />} />
          <Route path="/member/add" element={<MemberAddPage />} />
          <Route path="/member/:id" element={<MemberDetailPage />} />
          <Route path="/member/edit/:id" element={<MemberEditPage />} />

          <Route path="/transaction" element={<TransactionPage />} />

          <Route path="/operational" element={<OperationalPage />} />
          <Route path="/operational/add" element={<OperationalAddPage />} />
          <Route
            path="/operational/edit/:id"
            element={<OperationalEditPage />}
          />

          <Route path="/main-menu" element={<MainMenuPage />} />
          <Route path="/main-menu/add" element={<MainMenuAddPage />} />
          <Route path="/main-menu/edit/:id" element={<MainMenuEditPage />} />

          <Route path="/sub-menu" element={<SubMenuPage />} />
          <Route path="/sub-menu/add" element={<SubMenuAddPage />} />
          <Route path="/sub-menu/edit/:id" element={<SubMenuEditPage />} />

          <Route path="/product" element={<ProductPage />} />
          <Route path="/product/add" element={<ProductAddPage />} />
          <Route path="/product/edit/:id" element={<ProductEditPage />} />

          <Route path="/digital-product" element={<DigitalProductPage />} />
          <Route path="/digital-product/add" element={<DigitalProductAddPage />} />
          <Route path="/digital-product/edit/:id" element={<DigitalProductEditPage />} />

          <Route path="/profile/me" element={<ProfilePage />} />
          <Route path="/profile/me/edit" element={<ProfileEditPage />} />

          <Route path="*" element={<HomePage />} exact />
        </Routes>
      </DashboardLayout>
    </BrowserRouter>
  );
}
