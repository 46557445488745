import { useEffect, useState } from "react";
import { Badge, Button, Form, Input, Pagination, Table } from "react-daisyui";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { ActionIndex } from "src/components/action-index";
import { PaginationComponent } from "src/components/pagination";
import { TableComponent } from "src/components/table";
import { OperationalCostServices } from "src/services/OperationalCostServices";

export function OperationalPage() {
  const navigate = useNavigate();

  const operationalCostServices = new OperationalCostServices();
  const limit = 20;

  const [operationalCosts, setOperationalCosts] = useState({
    data: [],
    meta: {
      total: 0,
    },
  });

  const [tempOperationalCosts, setTempOperationalCosts] = useState({
    data: [],
    meta: {
      total: 0,
    },
  });

  const [offset, setOffset] = useState(0);

  useEffect(() => {
    fetch();
  }, []);

  async function fetch(pOffset) {
    const res = await operationalCostServices.gets({
      limit: limit,
      offset: pOffset ?? offset,
    });

    if (res?.message != null) {
      toast.error(res.message[0]);
    } else if (res) {
      const temp = res.data.map((item) => {
        return {
          id: item.id,
          name: item.name,
          costDate: item.cost_date,
          taxAmount: item.tax_amount,
          amount: item.amount,
          totalAmount: item.total_amount,
        };
      });
      setOperationalCosts({
        data: temp,
        meta: {
          total: res.meta.total,
        },
      });

      setTempOperationalCosts({
        data: temp,
        meta: {
          total: res.meta.total,
        },
      });
    }
  }

  const headerTable = [
    { code: "id", name: "ID" },
    { code: "name", name: "Name" },
    { code: "costDate", name: "Date" },
    { code: "taxAmount", name: "Tax" },
    { code: "amount", name: "Amount" },
    { code: "totalAmount", name: "Total" },
    { code: "action", name: "Action" },
  ];

  const handleSearch = (text) => {
    const filtered = tempOperationalCosts.data.filter((item) =>
      item.name.tolowerCase().includes(text.tolowerCase())
    );

    setOperationalCosts({
      data: filtered,
      meta: {
        total: filtered.length,
      },
    });
  };

  return (
    <div className="col-span-12">
      {/* Search Bar */}
      <div className="flex gap-4 flex-col md:flex-row">
        <div className="flex gap-2">
          <Input
            bordered
            type="date"
            className="w-full md:w-auto bg-base-100 rounded-lg"
          />
          <Input
            bordered
            type="date"
            className="w-full md:w-auto bg-base-100 rounded-lg"
          />
          <Button color="neutral">Apply</Button>
        </div>

        <div className="flex lg:justify-end justify-start grow">
          <Button
            className="bg-primary-main"
            color="neutral"
            onClick={() => {
              navigate("/operational/add");
            }}
          >
            Add Operational
          </Button>
        </div>
        {/* <form className="flex justify-end gap-2 grow" onSubmit={handleSearch}>
          <Input
            bordered
            type="text"
            placeholder="Search"
            className="w-full md:w-auto bg-base-100 rounded-lg"
          />
          <Button color="ghost">Search</Button>
        </form> */}
      </div>

      <br />

      {/* Tabel */}
      <TableComponent
        header={headerTable}
        action={[
          {
            color: "info",
            name: "Edit",
            callback: (id) => {
              navigate("/operational/edit/" + id);
            },
          },
          {
            color: "error",
            name: "Delete",
            callback: async (id) => {
              const res = await operationalCostServices.delete({ id });
              if (res?.message != null) {
                toast.error(res.message[0]);
              } else if (res) {
                fetch();
                toast.success("Success delete operational cost");
              }
            },
          },
        ]}
        data={operationalCosts.data || []}
      />

      <br />

      {/* Pagination */}
      <div className="flex justify-end">
        <PaginationComponent
          active={offset}
          data={Array.from(
            { length: operationalCosts?.meta?.total / limit + 1 },
            (_, index) => index + 1
          )}
          callback={(index) => {
            fetch(index * limit);
            setOffset(index);
          }}
        />
      </div>
    </div>
  );
}
